import { detailTabbedViewEnum, detailView } from "../detail-view-enum";

export interface IAlert {
  id: number,
  userId: string,
  customerCode: string,
  customer: string,
  title: string,
  templateGroup: string,
  code: string,
  alertDate: Date,
  notification: string,
  isRead: boolean,
  detailsView: detailView,
  detailsTab: detailTabbedViewEnum,
  reference: string  
}

export class Alert implements IAlert {
  id: number = 0;
  userId: string = '';
  customerCode: string = '';
  customer: string = '';
  title: string = '';
  templateGroup: string = '';
  code: string = '';
  alertDate: Date = new Date();
  notification: string = '';
  isRead: boolean = false;
  detailsView: detailView = detailView.none;
  detailsTab: detailTabbedViewEnum = detailTabbedViewEnum.none;
  reference: string = '';  
  constructor(
    id: number,
    userId: string,
    customerCode: string,
    customer: string,
    title: string,
    templateGroup: string,
    code: string,
    alertDate: Date,
    notification: string,
    isRead: boolean,
    detailsView: detailView,
    detailsTab: detailTabbedViewEnum,
    reference: string) {
      this.id = id;
      this.userId = userId;
      this.customerCode = customerCode;
      this.customer = customer;
      this.title = title;
      this.templateGroup = templateGroup;
      this.code = code;
      this.alertDate = alertDate;
      this.notification = notification;
      this.isRead = isRead;
      this.detailsView = detailsView;
      this.detailsTab = detailsTab;
      this.reference = reference;
  }
}
