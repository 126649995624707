<mat-card appearance="outlined" class="card-alert">
  <div class="notification-column">
    <mat-card-header class="title-header">
      <mat-card-title class="alert-title">{{alert.title}}</mat-card-title>
      <mat-card-subtitle class="alert-date">{{ alert.alertDate | date:'dd-MM-yyyy hh:mm:ss'}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="card-body">
      <div class="card-body-data">
        <div class="alert-body-details">
          <div class="alert-body-details-text">{{alert.notification}}</div>
        </div>
      </div>
    </mat-card-content>
  </div>

  <mat-card-actions class="action-column">
    <button class="dismiss" (click)="onRemove()">
      Dismiss</button>

    <button class="view-details" (click)="viewDetails(alert)" >View Details</button>
  </mat-card-actions>

</mat-card>
