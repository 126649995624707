import {Component, ViewChild, ChangeDetectorRef, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import {MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SchedulesService } from '../service/schedules/schedules.service';
import { IScheduleListModel } from '../models/schedules/schedule-list-model';
import { FormControl} from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { FilterEnum } from '../models/filter-enum';
import { ColumnSetup } from '../models/column-setup';
import { detailView } from '../models/detail-view-enum';
import { Subject } from 'rxjs';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { takeUntil, catchError, distinctUntilChanged, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';


@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrl: './schedules.component.css'
})
export class SchedulesComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator = {} as MatPaginator ;
  @ViewChild(MatSort) sort = {} as MatSort;
  dataSource = new MatTableDataSource();

  @Output() openFilter = new EventEmitter<string>();
  filter!: FilterData;
  filterParameter!: FilterData;
  //filterToSend!: FilterData;
  savedFilters: DataFilter[] = [];
  currentFilter: DataFilter = new DataFilter();
  filterData: FilterData = {};

  FilterEnum = FilterEnum;
  selectedButton = "";

  detailView = detailView;

  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];

  scheduleList: IScheduleListModel[] = []

  dateFrom = new FormControl();
  dateTo = new FormControl();  

  constructor(private schedulesService: SchedulesService, private cdr: ChangeDetectorRef,private communicationService: CommunicationService) { }

  ngOnInit(): void {

    this.schedulesService.filterData$.subscribe((value) => {
      this.filter = value;

      this.loadSavedFilters();
      this.updateData(this.filter);
    });

    this.schedulesService.sendActiveFilterToList$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (filterReceived) => {
          this.updateCurrentFilter(filterReceived.id, filterReceived.filterName, filterReceived.filter)
          this.updateData(this.currentFilter.filter);
        }
      });

    this.schedulesService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.loadSavedFilters();
          this.updateCurrentFilter(0, t, {});
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'carrier', header: 'Carrier', type: 'string', visible: true },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'dischargePort', header: 'Discharge Port', type: 'string', visible: true },
      { name: 'stackEnd', header: 'Stack End', type: 'date', visible: true },
      { name: 'loadPort', header: 'Load Port', type: 'string', visible: true },
      { name: 'loadETD', header: 'Load ETD', type: 'date', visible: true },
      { name: 'serviceName', header: 'Service Name', type: 'string', visible: true },
      { name: 'actions', header: 'Action', type: 'string', visible: true },
    ]

    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  updateData(filterData?: FilterData): void {

    this.schedulesService.getScheduleList(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value).subscribe(data => {
      this.configureColumns();
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });

    this.filterParameter = {};
  }

  onOpenFilter(openFiler: FilterEnum) {
    this.communicationService.toggleFilter(openFiler);
    this.sendingActiveFilter();
  }

  loadSavedFilters() {
    this.schedulesService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.currentFilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.currentFilter.filterName);
            this.updateCurrentFilter(filterId, this.currentFilter.filterName, this.getSavedFilterFromId(filterId));
            this.updateData();
          }
        }
      });
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');
    this.filterParameter[key] = dataArray;
  }

  setupFilter(column: string) {
    this.dataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.currentFilter.id === filterId) {
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.updateCurrentFilter(filterId, this.getSavedFilterNameFromId(filterId), this.getSavedFilterFromId(filterId))
    }
    this.updateData(this.currentFilter.filter)
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  sendingActiveFilter() {
    this.schedulesService.sendingActiveFilterToFilter(this.currentFilter)
  }

  //selectButton(button: string) {
  //  if (this.selectedButton === button) {
  //    this.selectedButton = '';
  //    this.applyStatusFilter('');
  //  } else {
  //    this.selectedButton = button;
  //    this.applyStatusFilter(button);
  //  }
  //}

  //applyStatusFilter(filterValue: string) {
  //  if (!filterValue) {
  //    this.dataSource.filter = '';
  //  } else {
  //    this.dataSource.filter = filterValue.trim().toLowerCase();
  //  }
  //}

  viewDetails(row: any, detailViewToOpen: detailView): void {
    console.log('Viewing details for:', row);
    this.communicationService.toggleDetailView(detailViewToOpen);
    this.communicationService.getDetailViewParameter(row['voyageNo'])
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: ColumnSetup[]) {
    return columns.map(col => col.name);

  }

  runReportClick(): void {
    // Replace with your desired logic (e.g., open a dialog, navigate, etc.)
    // console.log('Run Report Button clicked selectedCountry = ', this.dateTo.value );
    //this.applyParameters()

    this.updateData(this.filter);
  }

  handleButtonClick(row: any): void {
    // Replace with your desired logic (e.g., open a dialog, navigate, etc.)
    console.log('Button clicked for row:', row);
    alert(row['voyageNo'])
  }

  //onOpenFilter(openFiler: FilterEnum) {
  //  this.schedulesService.transferSortingOptions(this.displayedColumns);
  //  this.communicationService.toggleFilter(openFiler);
  //}

  

  

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
