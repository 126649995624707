
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, mergeMap, Observable, of, tap, map } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FilterData, UpdateData, } from '../../models/list-model';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { IAlert, Alert } from '../../models/alerts/alerts';
import { detailTabbedViewEnum, detailView } from '../../models/detail-view-enum';
import { AlertNotificationModel } from '../../models/alerts/alert-notification-model';
import { AlertSubscriptionModel } from '../../models/alerts/alert-subscription-model';
import { AlertSubscriptionInputModel } from '../../models/alerts/alert-subscription-input-model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private apiUrl = environment.apiUrl;
  private jsonData: string | undefined;

  private filterDataTransfer = new BehaviorSubject<FilterData>({});
  filterData$ = this.filterDataTransfer.asObservable();

  private statusFilterSubject = new BehaviorSubject<string | null>(null);
  statusFilterToggle$ = this.statusFilterSubject.asObservable();

  private sendFilter = new BehaviorSubject<string | null>(null);
  sendFilter$ = this.sendFilter.asObservable();

  private filterNameSaved = new BehaviorSubject('');
  filterNameSaved$ = this.filterNameSaved.asObservable();

  private sendFilterId = new BehaviorSubject<number>(-1);
  sendFilterId$ = this.sendFilterId.asObservable()

  private sendActiveFilterToFilter = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilter$ = this.sendActiveFilterToFilter.asObservable();

  private sendFilterTransfer = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  sendFilterTransfer$ = this.sendFilterTransfer.asObservable();

  private sendActiveFilterToList = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToList$ = this.sendActiveFilterToList.asObservable();

  constructor(private http: HttpClient) { }

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openSuccessSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  openFailSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackbar-fail']
    });
  }


  getAlerts(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): Observable<Alert[]> {
    let url = this.apiUrl + '/Alert/GetAlerts';

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);

        url = url + '?filter=' + jsonData;
      }
    }
    else if (filterId && filterId > 0) {
      url = url + '?filterId=' + filterId.toString();
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }
    return this.http.get<Alert[]>(url).pipe(
      map(response => response.map(item => {
        const detailsView = detailView[item.detailsView.toString() as keyof typeof detailView];
        const detailsTab = detailTabbedViewEnum[item.detailsTab.toString() as keyof typeof detailTabbedViewEnum];
        return new Alert(
          item.id,
          item.userId,
          item.customerCode,
          item.customer,
          item.title,
          item.templateGroup,
          item.code,
          item.alertDate,
          item.notification,
          item.isRead,
          detailsView,
          detailsTab,
          item.reference
        );
      }))
    );
  }

  getFilters(): Observable<DataFilter[]> {
    const url = this.apiUrl + '/Alert/GetFilters';
    return this.http.get<DataFilter[]>(url);
  }

  saveAlertFilter(filterName: string, filterData?: FilterData): Observable<any> {
    const url = this.apiUrl + '/Alert/SaveFilter?filterName=' + filterName;

    let body = {};

    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        body = JSON.stringify(filteredData);
      }
    }
    console.log(url)

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );

  }

  deleteAlertFilter(filterId: number): Observable<any> {
    const url = this.apiUrl + '/Alert/DeleteFilter?filterId=' + filterId;

    return this.http.delete<string>(url)
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );

  }

  updateAlertStatus(alertId: number): Observable<string> {
    const url = `${this.apiUrl}/Alert/UpdateAlertToRead?alertId=${alertId}`;
    return this.http.put<string>(url, {}).pipe(
      catchError(error => {
        // Display the error message as an alert
        window.alert('An error occurred: ' + error.error);
        // Return an observable with a user-facing error message
        return of(`Error: ${error.message}`);
      }),
      tap(response => {
        // if (response === null) {
        //   this.openSuccessSnackBar('Read');
       // }
      })
    );
  }

  subscribeToAlertGroup(expression: AlertNotificationModel): Observable<string> {

    let url = this.apiUrl + '/Alert/SubscribeAlertGroup';
    return this.http.post<string>(url, expression)
      .pipe(
        catchError(error => {
          this.openFailSnackBar('Subscription to ' + expression.TemplateGroup + ' unsuccessful');
          return of(`Error: ${error.message}`);
        }), mergeMap(response => {
          if (response === null) {
            this.openSuccessSnackBar('Subscribes to ' + expression.TemplateGroup + ' successful');
          }
          return of(response);
        })
      );

  }

  getUnReadAlerts(): Observable<Alert[]> {
    let url = this.apiUrl + '/Alert/GetUnreadAlerts';
    return this.http.get<Alert[]>(url)
      .pipe(
        map(response => response.map(item => {
          const detailsView = detailView[item.detailsView.toString() as keyof typeof detailView];
          const detailsTab = detailTabbedViewEnum[item.detailsTab.toString() as keyof typeof detailTabbedViewEnum];
          return new Alert(
            item.id,
            item.userId,
            item.customerCode,
            item.customer,
            item.title,
            item.templateGroup,
            item.code,
            item.alertDate,
            item.notification,
            item.isRead,
            detailsView,
            detailsTab,
            item.reference
          );
        }))
      );
  }

  getAlertsForGRRef(grRef: string | undefined): Observable<Alert[]> {
    const url = this.apiUrl +  '/Alert/GetAlertsForGRRef?grref=' + grRef;
    return this.http.get<Alert[]>(url)
    .pipe(
      map(response => response.map(item => {
       const detailsView = detailView[item.detailsView.toString() as keyof typeof detailView];
       const detailsTab = detailTabbedViewEnum[item.detailsTab.toString() as keyof typeof detailTabbedViewEnum];
       return new Alert(
         item.id,
         item.userId,
         item.customerCode,
         item.customer,
         item.title,
         item.templateGroup,
         item.code,
         item.alertDate,
         item.notification,
         item.isRead,
         detailsView,
         detailsTab,
         item.reference
       );
     }))   
    );
  }


  private convertFilterDataToJsonString(filterData: FilterData): string {
    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        return JSON.stringify(filteredData);
      }
    }
    return '';
  }

  transferFilterData(filterData: FilterData) {
    this.filterDataTransfer.next(filterData);
  }

  toggleStatusFilter(statusFilterName: string) {
    this.statusFilterSubject.next(statusFilterName);
  }

  sendFilterSetupToFilter(filter: string) {
    this.sendFilter.next(filter);
  }

  filterNameSavedSend(savedFilterName: string) {
    console.log('FilterSaved')

    this.filterNameSaved.next(savedFilterName);
  }

  sendingFilterId(filterId: number) {
    this.sendFilterId.next(filterId);
  }

  sendingActiveFilterToFilter(filter: DataFilter) {
    this.sendActiveFilterToFilter.next(filter);
  }

  sendingFilterTransferModel(transferModel: FilterTransferModel) {
    this.sendFilterTransfer.next(transferModel);
  }

  sendingActiveFilterToList(filter: DataFilter) {
    this.sendActiveFilterToList.next(filter);
  }

  getAlertSubscriptionTemplates(subscription: AlertNotificationModel): Observable<AlertSubscriptionModel[]> {
    const url = `${this.apiUrl}/Alert/GetSubscriptionTemplates`;
    return this.http.post<AlertSubscriptionModel[]>(url, subscription);
  }

  updateAlertSubscription(subscriptionInput: AlertSubscriptionInputModel): Observable<string> {
    const url = `${this.apiUrl}/Alert/SubscribeAlertGroup`;
    // Use the subscriptionInput object to create the request body
    return this.http.post<string>(url, subscriptionInput, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );
  }
}      

