<div class="dv-content-wrap">

  <div class="dv-header-wrap">
    <div class="detail-header">
      <h3> {{voyage.carrier}} - {{voyage.vesselName}}</h3>
    </div>
    <div class="dv-button-wrapper">
      <button class="primary-button">
        <mat-icon>add_circle</mat-icon> Add Booking
      </button>
    </div>
  </div>

  <div class="dv-summary-list">
    <div class="dv-summary-column">
      <span> Load Port: {{voyage.loadPort}}</span>
      <span> Discharge Port: {{voyage.dischargePort}}</span>
      <span> Service Name: {{voyage.serviceName}}</span>
    </div>
    <div class="dv-summary-column">
      <span> Stack Start: {{voyage.stackStart  | date: 'dd MMM yyyy'}}</span>
      <span> Stack End: {{voyage.stackEnd  | date: 'dd MMM yyyy'}}</span>
      <span> Load ETD: {{voyage.loadETD  | date: 'dd MMM yyyy'}}</span>
    </div>
  </div>

  <div #mapContainer id="map" class="map-view">
    <div class="map-legend">
      <img class="legend-image" src="/assets/Dashed Line.svg" alt="Dashed line">
      <div class="legend-text">Expected route</div>
    </div>
  </div>
<div class="dv-timeline">
  <div class="dv-timeline-heading"><h4>Projected Timeline</h4>
  </div>
  <div *ngIf="isMinWidth(0)" class="dv-timeline-header-row">
      <ng-container *ngFor="let column of displayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <strong>{{ column[1] }}</strong>
        </div>
      </ng-container>
      <div>
        <strong>Duration</strong>
      </div>
    </div>
    <div *ngFor="let row of scheduleLines" class="dv-timeline-data-row" id="mobileCard">
      <ng-container *ngFor="let column of displayedColumns">
        <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
          <ng-container *ngIf="column[0] === 'eta'">
            {{ getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="column[0] === 'etd'">
            {{ getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="column[0] !== 'eta'&& column[0] !== 'etd'">
            {{getValueForRowColumn(row, column[0])}}
          </ng-container>
        </div>
      </ng-container>

    <div>
      {{getTimeDifference(row.eta | date: 'yyyy-MM-dd')}}
    </div>
  </div>
</div>
  
</div>
