<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #drawer class="sidenav custom-sidenav" [class.expanded]="isExpanded" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">

    <div class="mobile-extra-content">
      <div class="sidenav-logo"></div>

                <div class="welcome-banner">
                  <div class="welcome-message">
                    Welcome back, {{ userInfoModel.userFullName }} | {{ userInfoModel.userOrganisation }}
                  </div>
                </div>
              </div>
    <mat-nav-list class="nav-list">
      <a mat-list-item routerLink="/dashboard" disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="dashboard"></div>
          </div>
          <span class="item-label">Dashboard</span>
        </div>
      </a>
      <a mat-list-item routerLink="/alerts" disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="alerts"></div>
          </div>
          <span class="item-label">Alerts</span>
        </div>
      </a>
      <a mat-list-item routerLink="/booking management" disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="bookingManagement"></div>
          </div>
          <span class="item-label">Booking Management</span>
        </div>
      </a>
      <a mat-list-item routerLink="/document management" disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="documentManagement"></div>
          </div>
          <span class="item-label">Documents</span>
        </div>
      </a>
      <a mat-list-item routerLink="/shipments" disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="shipments"></div>
          </div>
          <span class="item-label">Shipments</span>
        </div>
      </a>
      <a mat-list-item routerLink="/schedules" disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="schedules"></div>
          </div>
          <span class="item-label">Schedules</span>
        </div>
      </a>
      <a mat-list-item routerLink="/analytics" disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="analytics"></div>
          </div>
          <span class="item-label">Analytics</span>
        </div>
      </a>
      <div class="divider-line"></div>
      <div class="sign-out-wrapper">

        <button class="menu-item sign-out" type="button" aria-label="logout" mat-icon-button (click)="logout()">
          <img class="menu-icon-wrapper" src="assets/SignOut.svg" alt="Sign Out">
          <span class="item-label">Sign Out</span>
        </button>


      </div>
    </mat-nav-list>
    <div class="bottom-section">

      <div class="help-section">
        <div class="help-section-header">
          <img src="assets/SealQuestion.svg" alt="">
          <h4>Need help?</h4>
        </div>
        <div class="help-section-links">
          <button class="help-section-button">How-to Videos</button>
          <button class="help-section-button">FAQs</button>
          <button class="help-section-button">Contact Support</button>
          <button class="help-section-button">Community Forums</button>
        </div>
      </div>
    </div>
  </mat-sidenav>




  <mat-sidenav #rightDrawer [@sidenavAnimation]="sidenavState" [ngClass]="currentClass" position="end" mode="over" (closedStart)="onRightDrawerClosed()">

    <ng-container *ngIf="activeRightDrawer === RightDrawer.alertSideNav">
      <div class="close-alertDrawer" (click)="closeRightDrawer()">
        <span>Close</span>
      </div>
      <div class="alert-sidenav-grid">
        <app-alert-sidenav></app-alert-sidenav>
        <div class="view-all-container">
          <button class="view-all-btn">View All</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeRightDrawer === RightDrawer.FilterSideNav">
      <div class="close-alertDrawer" (click)="closeRightDrawer()">
        <span>Close</span>
      </div>
      <div class="">
        <app-filter-sidenav></app-filter-sidenav>
      </div>
    </ng-container>

    <ng-container *ngIf="activeRightDrawer === RightDrawer.DetailViewSideNav">
      <div class="close-alertDrawer" (click)="closeRightDrawer()">
        <span>Close</span>
      </div>
      <div class="">
        <app-detailed-view-nav></app-detailed-view-nav>
      </div>
    </ng-container>

  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>