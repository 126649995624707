import { OnInit, Component, ViewChild, Output, EventEmitter, OnDestroy, inject } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BookingModel } from '../models/booking-data-model';
import { BookingService } from '../service/booking/booking.service';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ColumnSetup } from '../models/column-setup';
import { detailView } from '../models/detail-view-enum';
import { Subject } from 'rxjs';
import { takeUntil, catchError, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css']
})
export class BookingListComponent implements OnInit, OnDestroy {  

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isCustUser: boolean = false;

  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<BookingModel>;

  bookingData: BookingModel[] = [];
  filter!: FilterData;
  detailView = detailView;
  filterId: number = 0;
  startDate?: Date = new Date;
  endDate?: Date = new Date;

  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  bookingDataSource = new MatTableDataSource<BookingModel>();

  constructor(private bookingDataService: BookingService, private communicationService: CommunicationService) {  }

  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.bookingDataService.statusFilterToggle$
      .pipe(
        takeUntil(this.destroy$),
        skip(1)
      )
      .subscribe({
        next: 
        value => {
          if(value) {
            this.applyFilter(value);
          }else{
            this.applyFilter('');
          }
        }
      });
                                            

    this.bookingDataService.sendFilterTransfer$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (t) => {
          this.filter = t.filter;
          this.filterId = t.filterId;
          this.startDate = t.startDate;
          this.endDate = t.endDate;
          this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
        },
        error: (error) => {
          console.error('Send FilterModel Error', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      });
  }

  configureColumns(): void {
    this.displayedColumns = [
      {name:'appBookingStatusColor',header:'',type:'string',visible:true},
      {name:'customerCode',header:'Customer',type:'string',visible:!this.isCustUser},
      {name:'grRefNo',header:'GR Ref No',type:'string',visible:true},
      {name:'appBookingStatus',header:'Booking Status',type:'string',visible:true},
      {name:'loadPort',header:'Load Port',type:'string',visible:true},
      {name:'carrier',header:'Carrier',type:'string',visible:true},
      {name:'sailDate',header:'Sail Date',type:'date',visible:true},
      {name:'actions',header:'Actions',type:'string',visible:true}
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Cancelled':
        return '#DC6868';
      case 'Confirmed':
        return '#36DE6F';
      case 'Awaiting Approval':
        return '#FFD34F';
      default:
        return '#C2C2C2';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.bookingDataService.getBookings(filterId, filterData, startDate, endDate).subscribe(data => {
      this.configureColumns();    
      this.bookingDataSource.sort = this.sort;
      this.bookingDataSource.paginator = this.paginator;
      this.bookingDataSource.data = data;
    });
  }

  viewDetails(row: any, stringA: detailView): void {
    console.log('Viewing details for:', row);
    this.communicationService.toggleDetailView(stringA);
    this.communicationService.getDetailViewParameter(row.grRefNo.toString())
    this.openDetailDrawer.emit();
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  applyFilter(filterValue: string) {
    if (!filterValue) {
      this.bookingDataSource.filter = '';
    } else {
      this.bookingDataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  filterByStatus(status: string) {
    this.bookingDataSource.filterPredicate = (data: BookingModel, filter: string) => data.appBookingStatus === filter;
    this.bookingDataSource.filter = status;
  }

  filterByDateRange(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    this.bookingDataSource.filterPredicate = (data: BookingModel, filter: string) => {
      const date = new Date(data.sailDate);
      return date >= start && date <= end;
    };
    this.bookingDataSource.filter = `${startDate}-${endDate}`; // trigger the filter
  }


  //<---------------------------------------->
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
