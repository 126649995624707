<ng-container *ngIf="activeFilter === FilterEnum.bookingFilter">
    <app-bookingfilter (click)="onOpenFilter(FilterEnum.bookingFilter)"></app-bookingfilter>
</ng-container>
<ng-container *ngIf="activeFilter === FilterEnum.scheduleFilter">
  <app-schedules-filter (click)="onOpenFilter(FilterEnum.scheduleFilter)"></app-schedules-filter>
</ng-container>
<ng-container *ngIf="activeFilter === FilterEnum.shipmentFilter">
  <app-shipment-filter (click)="onOpenFilter(FilterEnum.shipmentFilter)"></app-shipment-filter>
</ng-container>
<ng-container *ngIf="activeFilter === FilterEnum.documentFilter">
  <app-document-filter (click)="onOpenFilter(FilterEnum.documentFilter)"></app-document-filter>
</ng-container>
<ng-container *ngIf="activeFilter === FilterEnum.alertFilter">
  <app-alert-filter (click)="onOpenFilter(FilterEnum.alertFilter)"></app-alert-filter>
</ng-container>
