<div class="icon-container">
    <button 
    matBadge="{{websocketMessages.length!=0 ? websocketMessages.length : ''}}" [matBadgeHidden]="websocketMessages.length!=0 ? false : true"
     class="icon-button" (click)="onOpenAlertsClick()">      
      <img src="assets/BellRinging.svg">
    </button>
    <button class="icon-button">
      <a href={{userInfoModel.accountUrl}}>
        <img src="assets/User.svg">
      </a>
        
    </button>
  </div>
