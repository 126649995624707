import { Component, OnInit} from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';

@Component({
  selector: 'app-detailed-view-nav',
  templateUrl: './detailed-view-nav.component.html',
  styleUrl: './detailed-view-nav.component.css'
})
export class DetailedViewNavComponent implements OnInit{

  detailView: detailView = detailView.none;
  detailTabbedView: detailTabbedViewEnum = detailTabbedViewEnum.none;
  detailViewEnum = detailView;
  detailTabbedViewEnum = detailTabbedViewEnum;

  constructor(private communicationService: CommunicationService) { }

  ngOnInit(): void {
    this.communicationService.detailedViewToggle$.subscribe(d => {
      this.detailView = d.detailView; 
       this.detailTabbedView = d.detailTabbedViewEnum; 
         });
  }
}
