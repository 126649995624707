<div class="container">

  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Shipments</h1>

      <div class="saved-views">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': selectedFilterId === savedFilter.id}" (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
      </div>
      </div>
    </div>


  </div>
  <div class="search-parameters-wrap">
    <mat-form-field>
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [formControl]="dateFrom">
      <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateFromPicker></mat-datepicker>
    </mat-form-field>



    <mat-form-field>
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="dateToPicker" placeholder="Choose a date" [formControl]="dateTo" />
      <mat-datepicker-toggle matIconSuffix [for]="dateToPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateToPicker>
      </mat-datepicker>
    </mat-form-field>

    <section style="display: inline-flex">
      <button class="runReportButton" (click)="runReportClick()">Search<mat-icon>arrow_forward</mat-icon></button>
    </section>
  </div>

  <div class="filter-btn-wrapper">
    <div class="legend-wrapper">

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'completed'}"
        (click)="selectButton('completed')">
        <img src="/assets/Confirmed.svg" alt="" class="icon">
        Completed
      </button>

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'active'}"
        (click)="selectButton('active')">
        <img src="/assets/Active.svg" alt="" class="icon">
        Active
      </button>
    </div>


    <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.shipmentFilter)">
      <div class="filter-icon-wrap">
        <div class="filter-icon"></div>
        Show Filters
      </div>
    </button>


  </div>


  <app-shipment-list></app-shipment-list>

</div>