export interface IScheduleListModel {
  id: number,
  carrierCode: string,
  carrier: string,
  vesselName: string,
  country: string,
  loadPortCode: string,
  loadPort: string,
  dischargePortCode: string,
  dischargePort: string,
  stackStart: Date,
  stackEnd: Date,
  stackStatus: string,
  loadETD: Date,
  serviceName: string,
  voyageNo: string,
  dischargeETA: Date,
}

export class ScheduleListModel implements IScheduleListModel {
  id: number = 0;
  carrierCode: string = '';
  carrier: string = '';
  vesselName: string = '';
  country: string = '';
  loadPortCode: string = '';
  loadPort: string = '';
  dischargePortCode: string = '';
  dischargePort: string = '';
  stackStart: Date = new Date();
  stackEnd: Date = new Date();
  stackStatus: string = '';
  loadETD: Date = new Date();
  serviceName: string = '';
  voyageNo: string = '';
  dischargeETA: Date = new Date();
}


