import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { SchedulesComponent } from '../schedules/schedules.component';
import { ShipmentsComponent } from '../shipments/shipments.component';
import { AnalyticsComponent } from '../analytics/analytics.component';
import { DocumentListComponent } from '../document-list/document-list.component';

import { GridsterComponent, GridsterItemComponent, GridsterConfig, GridsterItem, GridsterConfigService, GridType, DisplayGrid, CompactType } from 'angular-gridster2';

@Component({
  selector: 'app-widget-select',
  templateUrl: './widget-select.component.html',
  styleUrl: './widget-select.component.css'
})
export class WidgetSelectComponent {
  @Input()
  widget: any;
;
}
