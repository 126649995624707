import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Alert } from '../models/alerts/alerts';
import { ShipmentModel } from '../models/shipment/shipment-data-model';

@Component({
  selector: 'app-shipment-alert-tab',
  templateUrl: './shipment-alert-tab.component.html',
  styleUrl: './shipment-alert-tab.component.css'
})
export class ShipmentAlertTabComponent{

    
    @Input()alertData: Alert[] = [];
    @Input()shipmentData!: ShipmentModel;
  
    displayedColumns = [
      ['title', 'Alert'],
      ['alertDate', 'Time of Alert'],
      ['notification', 'Details'],
    ];

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: string[][]) {
    return columns.map(col => col[0]);

  }

  getDelayInDays(eta: Date, etd: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // Hours * minutes * seconds * milliseconds
    const etaDate = new Date(eta).getTime();
    const etdDate = new Date(etd).getTime();
    
    return Math.round((etaDate - etdDate) / oneDay); // Returns the difference in days
  }
}
