<div class="status-box">
    <div class="status-item">
        <span class="status-label">Original ETA</span>
        <span>{{shipmentData.eta | date: 'dd MMM yyyy'}}</span>
    </div>

    <div class="status-item">
      <span class="status-label">Current ETA</span>
      <span>{{shipmentData.eta | date: 'dd MMM yyyy'}}</span>
  </div>
  <div class="status-item">
    <span class="status-label">Status</span>
    <span>{{shipmentData.shipmentStatus}}</span>
</div>
    <div class="status-item">
        <span class="status-label">Original ETD</span>
        <span>{{shipmentData.etd | date: 'dd MMM yyyy'}}</span>
    </div>

    <div class="status-item">
        <span class="status-label">Current ETD</span>
        <span>{{shipmentData.etd | date: 'dd MMM yyyy' }}</span>
    </div>
    
    <div class="status-item">
      <span class="status-label">Delayed by</span>
      <span>{{ getDelayInDays(shipmentData.eta, shipmentData.eta) }} days</span>
    </div>
</div>

<div class="dv-timeline">

    <div class="dv-timeline-header-row">
        <ng-container *ngFor="let column of displayedColumns">
          <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
            <strong>{{ column[1] }}</strong>
          </div>
        </ng-container>
      </div>
      <div *ngFor="let row of alertData" class="dv-timeline-data-row" id="mobileCard">
        <ng-container *ngFor="let column of displayedColumns">
          <div class="dv-timeline-data" *ngIf="column[0] !== 'actions'">
            <ng-container *ngIf="column[0] === 'alertDate'">
              {{ getValueForRowColumn(row, column[0]) | date: 'dd MMM yyyy HH:mm:ss' }}
            </ng-container>
            <ng-container *ngIf="column[0] === 'details'">
              ________
            </ng-container>
            <ng-container *ngIf="column[0] !== 'details'&& column[0] !== 'alertDate'">
              {{getValueForRowColumn(row, column[0])}}
            </ng-container>
          </div>
        </ng-container>
    </div>
  </div>