<div class="filter-container" style="padding-top: 20px">
  <div class="filter-field-wrapper saved-view-name">
    <div class="filter-help" *ngIf="isSavedFilterNameEmpty"> 
      <img class="info-icon" alt="Info Icon" src="../../assets/Info.svg"> 
      <div class="help-text"> Enter a unique name to save your filters </div>
    </div>
    <mat-form-field>
      <mat-label>Saved Filter View Name</mat-label>
      <input matInput [formControl]="savedFilterName">
    </mat-form-field>
    <div class="action-buttons">
      <button mat-button class="small-button delete" (click)="deleteFilter()" *ngIf="datafilter.id !== 0">Delete View</button>
      <button mat-button class="small-button blue" (click)="save()" *ngIf="!isSavedFilterNameEmpty">Save View</button>
    </div>
  </div>
  
<div class="filter-field-wrapper">

  <mat-form-field *ngIf="carriers.value">
    <mat-label>All Carriers</mat-label>
    <mat-select [formControl]="carriers" multiple #multiSelect>
      <mat-option>
        <ngx-mat-select-search [formControl]="carriersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Carrier Not Found"></ngx-mat-select-search>
      </mat-option>
      <mat-select-trigger>
        {{carriers.value.length > 0 ? carriers.value[0].code : ''}}
        <span *ngIf="carriers.value[0]  && carriers.value.length > 1" class="example-additional-selection">
          (+{{(carriers.value.length || 0) - 1}} {{carriers.value.length === 2 ? 'other' : 'others'}})
        </span>
        <button class="clear-filter" (click)="clearLookupFilter(carriers)">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-select-trigger>
      <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">
        {{carrier.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <mat-form-field *ngIf="loadPorts.value">
    <mat-label>POL</mat-label>
    <mat-select [formControl]="loadPorts" multiple #multiSelect>
      <mat-option>
        <ngx-mat-select-search [formControl]="loadPortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Load Port Not found"></ngx-mat-select-search>
      </mat-option>
      <mat-select-trigger>
        {{loadPorts.value.length > 0 ? loadPorts.value[0].code : ''}}
        <span *ngIf="loadPorts.value[0]  && loadPorts.value.length > 1" class="example-additional-selection">
          (+{{(loadPorts.value.length || 0) - 1}} {{loadPorts.value.length === 2 ? 'other' : 'others'}})
        </span>
        <button class="clear-filter" (click)="clearLookupFilter(loadPorts)">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-select-trigger>
      <mat-option *ngFor="let loadPort of filteredLoadPorts | async" [value]="loadPort">
        {{loadPort.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="dischargePorts.value">
    <mat-label>POD</mat-label>
    <mat-select [formControl]="dischargePorts" multiple #multiSelect>
      <mat-option>
        <ngx-mat-select-search [formControl]="dischargePortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Discharge Port Not found"></ngx-mat-select-search>
      </mat-option>
      <mat-select-trigger>
        {{dischargePorts.value.length > 0 ? dischargePorts.value[0].code : ''}}
        <span *ngIf="dischargePorts.value[0]  && dischargePorts.value.length > 1" class="example-additional-selection">
          (+{{(dischargePorts.value.length || 0) - 1}} {{dischargePorts.value.length === 2 ? 'other' : 'others'}})
        </span>
        <button class="clear-filter" (click)="clearLookupFilter(dischargePorts)">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-select-trigger>
      <mat-option *ngFor="let dischargePort of filteredDischargePorts | async" [value]="dischargePort">
        {{dischargePort.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <div class="action-buttons">
    <button mat-button class="small-button" (click)="applyFilters()">Apply Filters</button>

    <button class="small-button soft-blue" (click)="clearAllFilters()">
      Clear All Filters
    </button>
  </div>

</div>
</div>
