<div class="container">
  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Alerts</h1>
      <div class="saved-views">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}" (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    View alerts from shipments you’ve subscribed to. Use the filters on the right to select which alerts you want to see.
  </div>

  <div class="search-parameters-wrap">
    <mat-form-field>
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [formControl]="dateFrom">
      <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateFromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="dateToPicker" placeholder="Choose a date" [formControl]="dateTo" />
      <mat-datepicker-toggle matIconSuffix [for]="dateToPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateToPicker>
      </mat-datepicker>
    </mat-form-field>

    <section style="display: inline-flex">
      <button class="runReportButton" (click)="runReportClick()">Search<mat-icon>arrow_forward</mat-icon></button>
    </section>
  </div>

  <div class="filter-btn-wrapper">
    <div class="legend-wrapper">
      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'true'}"
              (click)="selectButton('true')">
        <img src="/assets/Awaiting.svg" alt="" class="icon">
        Read
      </button>
      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'false'}"
              (click)="selectButton('false')">
        <img src="/assets/Cancelled.svg" alt="" class="icon">
        Unread
      </button>
    </div>
    <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.alertFilter)">
      <div class="filter-icon-wrap">
        <div class="filter-icon"></div>
        Show Filters
      </div>
    </button>
  </div>

  <div class="mat-elevation-z2  custom-card-radius" *ngIf="isMinWidth(760)">
    <table mat-table class="full-width-table" [dataSource]="alertDataSource" matSort aria-label="Elements">

      <ng-container *ngFor="let column of displayedColumns">
        <ng-container>
          <div>
            No alert to display
          </div>
        </ng-container>
        <ng-container [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row" [hidden]="!column.visible"
              [ngStyle]="column.name === 'documentStatusColor' ? {'background-color': getBackgroundColor( row['isRead'])} : {}">
            <ng-container>
              <ng-container *ngIf="column.name === 'notification'">
                <div class="title-header"><strong>{{row.title}}</strong></div>
                <div innerHTML="{{row[column.name]}}"></div>
              </ng-container>
              <ng-container *ngIf="column.name === 'customer'">
                {{row[column.name]}}
              </ng-container>
              <ng-container *ngIf="column.name === 'alertDate'">
                {{row[column.name] | date: 'dd MMM yyyy hh mm' }}
              </ng-container>
              <ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="column.name === 'actions'" class="legend-wrapper mat-align-right">
              <div class="parameters-filter-wrap ">
                <div class="legend-wrapper">
                  <button class="plannedRouteButton" (click)="viewDetails(row)">
                    View Details<mat-icon>arrow_forward</mat-icon>
                  </button>
                  <button class="dangerButton small-button" (click)="markAsRead(row.id)">
                    Mark as Read
                  </button>
                </div>
              </div>
            </ng-container>


          </td>
        </ng-container>

      </ng-container>
      <tr mat-header-row *matHeaderRowDef="extractedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: extractedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell center" style="padding: 24px 0px; text-align:center" [attr.colspan]="displayedColumns.length">

          <!-- Display loading spinner when isLoading is true -->
          <div *ngIf="isLoading"
               style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
          </div>

          <!-- Display "No alerts found" message when isLoading is false -->
          <ng-container *ngIf="!isLoading"
               style="display: flex; justify-content: center; align-items: center; background: white;">
            No alerts found.
          </ng-container>

        </td>
      </tr>
      


      <!--<ng-container matColumnDef="noRecord">
    <td mat-footer-cell *matFooterCellDef>No records found.</td>
  </ng-container>
  <ng-template [ngIf]="alertDataSource.data.length === 0">
    <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
  </ng-template>-->
    </table>

    <mat-paginator #paginator
                   [length]="alertDataSource.data.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]"
                   aria-label="Select page">
    </mat-paginator>
  </div>

  <div *ngIf="!isMinWidth(760)">
    <div *ngFor="let row of alertDataSource.filteredData" class="card" id="mobileCard">
      <ng-container *ngFor="let column of displayedColumns">
        <div>
          <strong>{{ column.header }} <span class="card-line">|</span></strong>
          <ng-container *ngIf="column.name === 'sailDate'">
            {{ getValueForRowColumn(row, column.name) | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container>
            {{ getValueForRowColumn(row, column.name) }}
          </ng-container>
        </div>
      </ng-container>
      <ng-container class="legend-wrapper">
        <div style="display:flex; flex-direction: row; justify-content: space-between">
          <button class="plannedRouteButton" (click)="viewDetails(row)">
            View Details<mat-icon>arrow_forward</mat-icon>
          </button>
          <button class="dangerButton small-button">
            Mark as Read
          </button>
        </div>
      </ng-container>
    </div>
  </div>

</div>








