export interface IBookingLine {
  lineNo: number;
  bookingRef: string;
  clientRef: string;
  containerStatus: string;
  appBookingStatus: string;
  rejectionReason: string | null;
  dischargePort: string;
  finalDestination: string;
  confirmedPackDate: Date | null;
  commodity: string;
  loadingPoint1: string;
  loadingPoint2: string;
  temperature: number;
  quantity: number;
  lateStackRequest: boolean;
}

export interface IBookingModel {
  customerCode: string;
  grRefNo: number;
  appBookingStatus: string;
  containerStatus: string;
  commodityCode: string;
  commodity: string;
  loadPortCode: string;
  loadPort: string;
  dischargePortCode: string;
  dischargePort: string;
  temperature: number;
  carrier: string;
  carrierCode: string;
  sailDate: Date;
  marketCode: string;
  market: string;
  lateStack: boolean;
  clientName: string;
  appBookingStatusColor:string
  bookingLines: IBookingLine[];
}

export class BookingModel implements IBookingModel {
  customerCode: string = '';
  grRefNo: number = 0;
  appBookingStatus: string = '';
  containerStatus: string = '';
  commodityCode: string = '';
  commodity: string = '';
  loadPortCode: string = '';
  loadPort: string = '';
  dischargePortCode: string = '';
  dischargePort: string = '';
  temperature: number = 0;
  carrierCode: string = '';
  carrier: string = '';
  sailDate: Date = new Date();
  marketCode: string = '';
  market: string = '';
  lateStack: boolean = false;
  clientName: string = '';
  appBookingStatusColor=this.appBookingStatus;
  bookingLines: IBookingLine[] = [];
}

export class BookingLine implements IBookingLine {
  lineNo: number = 0;
  bookingRef: string = '';
  clientRef: string = '';
  containerStatus: string = '';
  appBookingStatus: string = '';
  rejectionReason: string | null = null;
  dischargePort: string = '';
  finalDestination: string = '';
  confirmedPackDate: Date | null = null;
  commodity: string = '';
  loadingPoint1: string = '';
  loadingPoint2: string = '';
  temperature: number = 0;
  quantity: number = 0;
  lateStackRequest: boolean = false;
}
