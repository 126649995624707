<div class="container">

  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Schedules</h1>
      <div class="saved-views">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}"
            (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [formControl]="dateFrom" [min]="yesterday">
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="dateToPicker" placeholder="Choose a date" [formControl]="dateTo" [min]="setDate" />
        <mat-datepicker-toggle matIconSuffix [for]="dateToPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateToPicker>
        </mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-1">
      <button class="runReportButton" (click)="runReportClick()">Search<mat-icon>arrow_forward</mat-icon></button>
    </div>

    <div class="col-md-2">
      <mat-form-field>
        <mat-label>Quick Search</mat-label>
        <input matInput [formControl]="filterList">
      </mat-form-field>
    </div>

    <div class="col-md-1">
      <button class="runReportButton"
        (click)="applyFilterList(filterList.value)">Search<mat-icon>arrow_forward</mat-icon></button>
    </div>

    <div class="col-md-2 filter-btn-wrapper" style=" justify-content: end;">
      <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.scheduleFilter)">
        <div class="filter-icon-wrap">
          <div class="filter-icon"></div>
          Show Filters
        </div>
      </button>
    </div>
  </div>

  <div style="overflow-x:auto;" class="mat-elevation-z2" *ngIf="isMinWidth(760)">
    <table mat-table [dataSource]="scheduleDataSource" class="full-width-table" matSort aria-label="Elements">

      <ng-container *ngFor="let column of displayedColumns">
        <ng-container [matColumnDef]="column.name" id="test">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="column.name !== 'actions'">
              <ng-container *ngIf="column.name === 'loadETD'">
                {{ row[column.name] | date: 'dd MMM yyyy' }}
              </ng-container>
              <ng-container *ngIf="column.name === 'stackEnd'">
                {{ row[column.name] | date: 'dd MMM yyyy' }}
              </ng-container>
              <ng-container *ngIf="column.name === 'dischargeETA'">
                {{ row[column.name] | date: 'dd MMM yyyy' }}
              </ng-container>
              <ng-container *ngIf="column.name === 'siCutoffDateTime'">
                {{ row[column.name] | date: 'dd MMM yyyy' }}
              </ng-container>
              <ng-container *ngIf="column.name === 'indicators'">
                <button [ngClass]="row.hasSubscriptions ? 'indicator-btn-subbed' : 'indicator-btn'"
                  (click)="subscribeToAlert(row)">
                  <mat-icon svgIcon="Subscribe"></mat-icon>
                </button>
                <button [ngClass]="row.hasAlerts ? 'indicator-btn-alerts-unread' : 'indicator-btn'"
                  (click)="viewDetails(row, detailView.scheduleDetail,detailTabbedViewEnum.alertTab)">
                  <mat-icon svgIcon="ViewAlerts"></mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="column.name !== 'loadETD'&& column.name !== 'stackEnd' && column.name !== 'siCutoffDateTime'&& column.name !== 'dischargeETA'">
                {{ row[column.name] }}
              </ng-container>
  
            </ng-container>

            <!--Add a button for the 'actions' column-->
            <ng-container *ngIf="column.name === 'actions'">
              <button class="plannedRouteButton" color="primary"
                (click)="viewDetails(row,detailView.scheduleDetail,detailTabbedViewEnum.detailsTab)">
                View Details<mat-icon>arrow_right_alt</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getExtractedColumns(displayedColumns)"></tr>
      <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumns);"></tr>
    </table>

    <mat-paginator #paginator [length]="scheduleDataSource.data.length" [pageIndex]="0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
    </mat-paginator>
  </div>
  <!-- Cards for smaller screens -->
  <div *ngIf="!isMinWidth(760)">
    <div *ngFor="let row of scheduleDataSource.filteredData" class="card" id="mobileCard">
      <ng-container *ngFor="let column of displayedColumns">
        <div *ngIf="column.name !== 'actions' && column.name !== 'indicators'">
          <strong>{{ column.header }} <span class="card-line">:</span></strong>

          <ng-container *ngIf="column.name === 'loadETD' || column.name === 'stackEnd'">
            {{ getValueForRowColumn(row, column.name) | date: 'dd MMM yyyy' }}
          </ng-container>

          <ng-container *ngIf="column.name !== 'indicators' && column.name !== 'loadETD' && column.name !== 'stackEnd'">
            {{ getValueForRowColumn(row, column.name) }}
          </ng-container>
        </div>
      </ng-container>

      <div class="card-actions-section">
        <div class="indicators_card">
          <button [ngClass]="row.hasSubscriptions ? 'indicator-btn-subbed' : 'indicator-btn'"
            (click)="subscribeToAlert(row)">
            <mat-icon svgIcon="Subscribe"></mat-icon>
          </button>
          <button [ngClass]="row.hasAlerts ? 'indicator-btn-alerts-unread' : 'indicator-btn'"
            (click)="viewDetails(row, detailView.scheduleDetail, detailTabbedViewEnum.alertTab)">
            <mat-icon svgIcon="ViewAlerts"></mat-icon>
          </button>
        </div>
        <button class="plannedRouteButton" color="primary"
          (click)="viewDetails(row, detailView.scheduleDetail, detailTabbedViewEnum.detailsTab)">
          View Details <mat-icon>arrow_right_alt</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Disclaimer text -->
<div class="paragraph-wrap">
  <h4>Disclaimer</h4>
  <p class="disclaimer-text">
    You use information provided in our Sailing Schedules at your own risk. We do not warrant its accuracy and completeness or that it is up-to-date, free from errors or omissions. Any data which is an estimate by its nature (ETDs, ETAs etc.) is not and cannot be guaranteed as accurate. Additionally the data provided includes and is based upon data received from third parties (including Shipping Lines) and is beyond our control. and cannot be verified. Accordingly any reliance placed upon any part of the sailing schedule is at your own risk. Please see <a href="https://www.goglobal.group/terms-and-conditions/">GoGlobal's Terms & Condtions</a> for our full policy on information provided and use of our reports and platforms.
  </p>

</div>

</div>