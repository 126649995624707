import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-management',
  templateUrl: './alert-management.component.html',
  styleUrl: './alert-management.component.css'
})
export class AlertManagementComponent implements OnInit {

  toggleValue: boolean = false; // Initialize toggle state

  onToggleChange(index: number, event: any): void {
    console.log(`Toggle for ${this.oceanAlerts[index].name} changed to:`, event.checked);
    // Additional logic based on the toggle change can be added here
  }

   // Array of ocean alert types
   oceanAlerts = [
    { name: 'Container picked up at origin', toggled: false },
    { name: 'Container gate in', toggled: false },
    { name: 'Delay at origin port', toggled: false },
    { name: 'Shipment ETD changed', toggled: false },
    { name: 'Shipment vessel changed', toggled: false },
    { name: 'Shipment departure', toggled: false },
    { name: 'Shipment ETA changed', toggled: false },
    { name: 'Delay in transshipment port', toggled: false },
    { name: 'Shipment arriving within 48 hours', toggled: false },
    { name: 'Shipment arrival', toggled: false },
    { name: 'Shipment port changed', toggled: false },
    { name: 'Full container gate out', toggled: false },
    { name: 'POD delay', toggled: false },
    { name: 'Full container delivered at destination', toggled: false },
  ];

    // Array of air alert types
   airAlerts = [
    { name:  'Shipment picked up', toggled: false },
    { name: 'Departed from origin airport', toggled: false },
    { name: 'Arrived at destination airport', toggled: false },
    { name: 'Shipment delivered to destination', toggled: false },
  ];

  // Array of road alert types
  roadAlerts = [
    { name:  'Picked up at origin', toggled: false },
    { name: 'Delivered at destination', toggled: false },
    { name: 'Gate in at POD', toggled: false },
  ];

  // Array of document alert types
  docAlerts = [
    { name: 'New documents uploaded to shipment and waiting approval.', toggled: false },
    { name: 'Document disapproved.', toggled: false },
    { name: 'Document approved.', toggled: false },
  ];

  
  ngOnInit(): void {;}


constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

  this.matIconRegistry.addSvgIcon(
    'CancelIcon',
    this.domSanitizer.bypassSecurityTrustResourceUrl('assets/CancelIcon.svg')
  );

}

}
