import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Alert } from '../models/alerts/alerts';
import { AddBookingEnum } from '../models/booking-data-model';
import { Router } from '@angular/router';
import { BookingDataTransferModel } from '../models/booking-data-model';
import { CommunicationService } from '../service/communication/communication.service';
import { BookingLineModel } from '../models/bookings/booking-line-model';


@Component({
  selector: 'app-booking-line',
  templateUrl: './booking-line.component.html',
  styleUrl: './booking-line.component.css'
})
export class BookingLineComponent {
  @Input() bookingLineData: BookingLineModel[] = [];
  bookingTransfer: BookingDataTransferModel = new BookingDataTransferModel();
  today= new Date();

  constructor(private router: Router, private communicationService: CommunicationService) { }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: string[][]) {
    return columns.map(col => col[0]);
  }

  public editBookingLine(lineNo: number): void {
    var bookingLine = this.getBookingLine(lineNo);

     this.bookingTransfer = {
      appBookingCode: bookingLine.appBookingCode,
      appBookingLineNumber: bookingLine.appBookingLineNumber,
      scheduleCode: null,
      loadPortCode: null,
      dischargePortCode: null,
      carrierCode: null,
      bookingType: AddBookingEnum.editBookingLine
    };

    this.communicationService.closeRightNav();
    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
  }

  getBookingLine(bookingLineNumber: number): BookingLineModel {
    const editLine: BookingLineModel = this.bookingLineData
      .find(line => line.lineNo === bookingLineNumber) ?? new BookingLineModel();
    return editLine;
  }
}
