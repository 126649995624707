import { Component, Input, OnInit } from '@angular/core';
import { SchedulesService } from '../service/schedules/schedules.service';
import { IScheduleListModel } from '../models/schedules/schedule-list-model';

@Component({
  selector: 'app-widget-vesselperport',
  templateUrl: './widget-vesselperport.component.html',
  styleUrl: './widget-vesselperport.component.css'
})
export class WidgetVesselperportComponent implements OnInit{
  @Input()
  city: any;
  scheduleList: IScheduleListModel[] = [];
  numberOfPorts: number = 0;

  constructor(private schedulesService: SchedulesService) { }

  ngOnInit(): void {
    //this.getData();
  }

  //public getData(): void {
  //  this.schedulesService.getScheduleList(0, {}).subscribe(data => {
  //    this.scheduleList = data;
  //    this.scheduleList = this.scheduleList.filter(port => port.loadPort === this.city);
  //    console.log(this.scheduleList.length);
  //    this.numberOfPorts = this.scheduleList.length;
  //  });
  //}

  


}
