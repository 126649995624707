import { Component, Input, OnChanges, SimpleChanges, AfterViewInit, ViewChild, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { SchedulesService } from '../service/schedules/schedules.service';
import { IScheduleListModel, ScheduleListModel } from '../models/schedules/schedule-list-model';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { ScheduleLinesModel } from '../models/schedules/schedule-lines-model';
import * as L from 'leaflet';
import { CommunicationService } from '../service/communication/communication.service';

@Component({
  selector: 'app-schedules-detail-view',
  templateUrl: './schedules-detail-view.component.html',
  styleUrl: './schedules-detail-view.component.css',
  encapsulation: ViewEncapsulation.None
})
export class SchedulesDetailViewComponent implements OnInit, AfterViewInit {
  //@Input() schedule!: SailingSchedule;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  private map: any;
  private routePolyline: any;
  public scheduleLines: ScheduleLinesModel[] = [];
  private voyageCoordinates: ScheduleVoyageCoordinates[] = [];
  private sheduleParameter: string = '';
  public voyage = new ScheduleListModel();

  displayedColumns = [
    //['id', 'Id'],
    ['portCode', 'Port Code'],
    //['portName', 'Port Name'],
    //['transhipment', 'Transhipment'],
    //['portCountryCode', 'Country Code'],
    //['stackStatus', 'Stack Status'],
    //['loadingPort', 'Loading Port'],
    ['eta', 'ETA'],
    ['etd', 'ETD'],
    //['consolidatedETA', 'Consolidated ETA'],
    //['consolidatedETD', 'Consolidated ETD'],
    //['updatedStackStart', 'Updated Stack Start'],
    //['updatedStackEnd', 'Updated Stack End'],
    //['carrierCode', 'Carrier Code'],
   //['carrierName', 'Carrier Name'],
    //['voyageNumber', 'Voyage No'],
    //['serviceCode', 'Service Code'],
    //['actions', 'Action']
  ];

  constructor(private schedulesService: SchedulesService, private communicationService: CommunicationService) { }

  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { this.sheduleParameter = param });
    this.getScheduleListDetail(this.sheduleParameter);
    this.getScheduleLines(this.sheduleParameter)
    this.getCoordinates(this.sheduleParameter);
  }

  ngAfterViewInit(): void {
  }



  private initMap(): void {

    this.map = L.map('map').setView([this.voyageCoordinates[0].latitude, this.voyageCoordinates[0].longitude], 2);

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    // Add route polyline
    this.routePolyline = L.polyline(this.voyageCoordinates.map(p => [p.latitude, p.longitude]), { color: '#162a3e', dashArray: '5, 10' }).addTo(this.map);

    tiles.addTo(this.map);

  }

  private getCoordinates(voyageParam: string): void {    
    this.schedulesService.getVoyageCoordinates(voyageParam).subscribe(data =>
    {
      this.voyageCoordinates = data;
      this.initMap();
    })

  }

  private getScheduleLines(voyageParam: string): void {
    this.schedulesService.getScheduleLines(voyageParam).subscribe(data => { this.scheduleLines = data })
  }

  private getScheduleListDetail(voyageParam: string): void {
    this.schedulesService.getScheduleListDetail(voyageParam).subscribe(data => { this.voyage = data })
    console.log("voyage =" + this.voyage)
  }

  public getTimeDifference(eta: string | null): string {

    const savedTimestamp = Date.parse(eta!);

    // Get the current time in milliseconds
    const currentTime = Date.now() / 1000;

    // Calculate the time difference
    const timeDifferenceSeconds = currentTime - savedTimestamp / 1000;

    // Calculate days
    var days = Math.floor(timeDifferenceSeconds / (24 * 60 * 60));

    // Calculate remaining hours
    const remainingSeconds = timeDifferenceSeconds % (24 * 60 * 60);
    const hours = Math.floor(remainingSeconds / 3600);

    if (days <= 0) {
      var daysPos = days * -1;
      return daysPos.toString() + ' Days Remaining';
    }
    else {
      
      return days.toString() + ' Days Past';
    }

  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: string[][]) {
    return columns.map(col => col[0]);

  }


}
