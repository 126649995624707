import { Component, Input } from '@angular/core';
import { Alert } from '../models/alerts/alerts';

@Component({
  selector: 'app-alert-shipment-details',
  templateUrl: './alert-shipment-details.component.html',
  styleUrl: './alert-shipment-details.component.css'
})
export class AlertShipmentDetailsComponent {

  @Input()alertData: Alert[] = [];
  
  displayedColumns = [
    ['title', 'Alert'],
    ['alertDate', 'Time of Alert'],
    ['notifications', 'Details'],
  ];

  summaryDisplayedColumns = [
    ['title', 'Created at'],
    ['alertDate', 'Mode'],
    ['notifications', 'Container type'],
  ];

  originPortDisplayedColumns = [
    ['title', 'Port name'],
    ['alertDate', 'Gate in time'],
    ['notifications', 'Port departure time'],
  ];

  destinationPortDisplayedColumns = [
    ['title', 'Port name'],
    ['alertDate', 'Carrier ETA']
  ];

  inTransitDisplayedColumns = [
    ['title', 'From'],
    ['alertDate', 'To'],
    ['notifications', 'Vessel Name'],
    ['notifications', 'Voyage No.'],
    ['notifications', 'Departure'],
    ['notifications', 'Arrival'],
  ];

getValueForRowColumn(row: any, columnName: string): any {
  return row[columnName];
}

getExtractedColumns(columns: string[][]) {
  return columns.map(col => col[0]);

}

}
