export interface IAlertNotificationModel {
  AlertCode: string;
  CustomerCode: string;
  TemplateGroup?: string;
  ContainerNo?: string;  
  GRReference?: string;  
  LoadNumber?: string;  
  BookingNumber?: string;  
  LoadDate?: Date;
  DateTime?: Date;
  NewETA?: Date;
  NewETD?: Date;
  VesselName?: string;  
  NewVesselName?: string;  
  DeparturePort?: string;  
  DestinationPort?: string;  
  NewPort?: string;  
  PortName?: string;  
  AirportName?: string;  
  PODLocation?: string;  
}

export class AlertNotificationModel implements IAlertNotificationModel {
  AlertCode: string = '';
  CustomerCode: string = '';
  TemplateGroup?: string;
  ContainerNo?: string;
  GRReference?: string;
  LoadNumber?: string;
  BookingNumber?: string;
  LoadDate?: Date;
  DateTime?: Date;
  NewETA?: Date;
  NewETD?: Date;
  VesselName?: string;
  NewVesselName?: string;
  DeparturePort?: string;
  DestinationPort?: string;
  NewPort?: string;
  PortName?: string;
  AirportName?: string;
  PODLocation?: string;  
}
