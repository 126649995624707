<div class="container">

  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Booking Management</h1>
      <div class="saved-views">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': selectedFilterId === savedFilter.id}" (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
        </div>
      </div>
    </div>
    <div class="btn-header">
      <button mat-button class="add-booking-btn">
        <img src="/assets/PlusCircle.svg" alt="" class="icon">
        Add booking
      </button>
    </div>
  </div>

  <div class="search-parameters-wrap">
    <mat-form-field>
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [formControl]="dateFrom">
      <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateFromPicker></mat-datepicker>
    </mat-form-field>



    <mat-form-field>
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="dateToPicker" placeholder="Choose a date" [formControl]="dateTo" />
      <mat-datepicker-toggle matIconSuffix [for]="dateToPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateToPicker>
        <!--<mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button matDatepickerApply>Apply</button>
        </mat-datepicker-actions>-->
      </mat-datepicker>
    </mat-form-field>


    <section style="display: inline-flex">
      <button class="runReportButton" (click)="runReportClick()">Search<mat-icon>arrow_forward</mat-icon></button>
    </section>


  </div>

  <div class="filter-btn-wrapper">
    <div class="legend-wrapper">

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'confirmed'}"
        (click)="selectButton('confirmed')">
        <img src="/assets/Confirmed.svg" alt="" class="icon">
        Confirmed
      </button>

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'draft'}"
        (click)="selectButton('draft')">
        <img src="/assets/Draft.svg" alt="" class="icon">
        Saved as draft
      </button>

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'awaiting'}"
        (click)="selectButton('awaiting')">
        <img src="/assets/Awaiting.svg" alt="" class="icon">
        Awaiting approval
      </button>

      <button mat-button class="alert-btns" [ngClass]="{'selected': selectedButton === 'cancelled'}"
        (click)="selectButton('cancelled')">
        <img src="/assets/Cancelled.svg" alt="" class="icon">
        Booking Cancelled
      </button>



    </div>


    <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.bookingFilter)">
      <div class="filter-icon-wrap">
        <div class="filter-icon"></div>
        Show Filters
      </div>
    </button>


  </div>


  <app-booking-list></app-booking-list>

</div>
