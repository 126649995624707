<div class="filter-container">
  <div class="filter-field-wrapper saved-view-name">
    <div class="filter-help" *ngIf="isSavedFilterNameEmpty">
      <img class="info-icon" alt="Info Icon" src="../../assets/Info.svg">
      <div class="help-text"> Enter a unique name to save your filters </div>
    </div>
    <mat-form-field>
      <mat-label>Saved Filter View Name</mat-label>
      <input matInput [formControl]="savedFilterName">
    </mat-form-field>
    <div class="action-buttons">
      <button mat-button class="small-button delete" (click)="deleteFilter()" *ngIf="datafilter.id !== 0">Delete View</button>
      <button mat-button class="small-button blue" (click)="save()" *ngIf="!isSavedFilterNameEmpty">Save View</button>
    </div>
  </div>

  <div class="filter-field-wrapper">

    <mat-form-field>
      <mat-label>GR Ref No.</mat-label>
      <input matInput [formControl]="grRefNos">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Search Container No.</mat-label>
      <input matInput [formControl]="containerNos">
    </mat-form-field>

    <ng-container *ngIf="!isCustUser">
      <mat-form-field *ngIf="customers.value">
        <mat-label>Customer</mat-label>
        <mat-select [formControl]="customers" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{customers.value.length > 0 ? customers.value[0].code : ''}}
            <span *ngIf="customers.value[0]  && customers.value.length > 1" class="example-additional-selection">
              (+{{(customers.value.length || 0) - 1}} {{customers.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(customers)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
            {{customer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="alertGroups.value">
      <mat-label>Alert Group</mat-label>
      <mat-select [formControl]="alertGroups" multiple>
        <mat-option>
          <ngx-mat-select-search [formControl]="alertGroupsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Alert Not Found"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{alertGroups.value.length > 0 ? alertGroups.value[0].code : ''}}
          <span *ngIf="alertGroups.value[0]  && alertGroups.value.length > 1" class="example-additional-selection">
            (+{{(alertGroups.value.length || 0) - 1}} {{alertGroups.value.length === 2 ? 'other' : 'others'}})
          </span>
          <button class="clear-filter" (click)="clearLookupFilter(alertGroups)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-select-trigger>
        <mat-option *ngFor="let alertGroup of filteredAlertGroups | async" [value]="alertGroup">
          {{alertGroup.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="templateGroups.value">
      <mat-label>Alert Type</mat-label>
      <mat-select [formControl]="templateGroups" multiple>
        <mat-option>
          <ngx-mat-select-search [formControl]="templateGroupsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Alert Type Not Found"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{templateGroups.value.length > 0 ? templateGroups.value[0].code : ''}}
          <span *ngIf="templateGroups.value[0]  && templateGroups.value.length > 1" class="example-additional-selection">
            (+{{(templateGroups.value.length || 0) - 1}} {{templateGroups.value.length === 2 ? 'other' : 'others'}})
          </span>
          <button class="clear-filter" (click)="clearLookupFilter(templateGroups)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-select-trigger>
        <mat-option *ngFor="let templateGroup of filteredTemplateGroups | async" [value]="templateGroup">
          {{templateGroup.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="transportModes.value">
      <mat-label>All Transport Modes</mat-label>
      <mat-select [formControl]="transportModes" multiple>
        <mat-option>
          <ngx-mat-select-search [formControl]="transportModesMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Transport Mode Not Found"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{transportModes.value.length > 0 ? transportModes.value[0].code : ''}}
          <span *ngIf="transportModes.value[0]  && transportModes.value.length > 1" class="example-additional-selection">
            (+{{(transportModes.value.length || 0) - 1}} {{transportModes.value.length === 2 ? 'other' : 'others'}})
          </span>
          <button class="clear-filter" (click)="clearLookupFilter(transportModes)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-select-trigger>
        <mat-option *ngFor="let transportMode of filteredTransportModes | async" [value]="transportMode">
          {{transportMode.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="action-buttons">
      <button mat-button class="small-button" (click)="applyFilters()">Apply Filters</button>

      <button class="small-button soft-blue" (click)="clearAllFilters()">
        Clear All Filters
      </button>
    </div>

  </div>
</div>
