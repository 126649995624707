<div class="dialog-wrap">
  <h3 class="dialog-title" >Rejection Reason?</h3>
  <div class="dialog-field-wrap">
    <div mat-dialog-content class="dialog-reason">
      <mat-form-field appearance="fill">
        <mat-label>Reason Code</mat-label>
        <mat-select [(value)]="data.reasonCode">
          <mat-option value="notFilledIn">Not Filled In</mat-option>
          <mat-option value="wrongData">Wrong Data</mat-option>
          <mat-option value="wrongDate">Wrong Date</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div mat-dialog-content class="dialog-reason">
      <mat-form-field appearance="fill">
        <mat-label>Rejection Reason Detail</mat-label>
        <textarea matInput [(ngModel)]="data.reasonDetail"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="dialog-button-wrap">
    <button class="dialog-cancel-button" mat-button (click)="onCancel()">Cancel</button>
    <button class="dialog-action-button" mat-button (click)="onReject()">Reject</button>
  </div>
</div>
