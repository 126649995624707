import { FilterData } from '../models/list-model';

export interface ISavedFilters {
  id: number,
  filterName: string,
  filter: string
}

export class SavedFilters implements ISavedFilters{
  id: number = 0;
  filterName: string = "";
  filter: string = "";
}


export interface ICurrentFilter {
  filterType: string,
  filterName: string,
  filter: string
}

export class CurrentFilter implements ICurrentFilter {
  filterType: string = "";
  filterName: string = "";
  filter: string = "";
}

export interface IDataFilter {
  id: number,
  filterName: string,
  filter: FilterData
}

export class DataFilter implements IDataFilter {
  id: number = 0;
  filterName: string = "";
  filter: FilterData = {};
;
}

export interface IFilterTransferModel {
  filterId: number;
  filter: FilterData;
  startDate?: Date;
  endDate?: Date;
}

export class FilterTransferModel implements IFilterTransferModel {
  filterId = -1;
  filter: FilterData = {};
  startDate? = new Date;
  endDate? = new Date;

  constructor(filterId: number, filter: FilterData, startDate?: Date, endDate?: Date) {
    this.filterId = filterId;
    this.filter = filter;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
