<div class="dialog-wrap">
  <h3 class="dialog-title">Create Saved Filter View</h3>
  <div class="dialog-field-wrap">
    <div mat-dialog-content class="dialog-reason">
      <mat-form-field appearance="fill">
        <mat-label>Saved Filter View Name</mat-label>
        <textarea matInput [(ngModel)]="data.filterName"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="dialog-button-wrap">
    <button class="dialog-cancel-button" mat-button (click)="onCancel()">Cancel</button>
    <button class="dialog-action-button" mat-button (click)="onSave()">Save</button>
  </div>
</div>
