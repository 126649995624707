<div class="container">

  <div class="title-wrapper">
    <div class="saved-view-wrap">
      <h1>Schedules</h1>
      <div class="saved-views">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}" (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="senzo">
    <div class="search-parameters-wrap">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="dateFromPicker" placeholder="Choose a date" [formControl]="dateFrom">
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>
      </mat-form-field>



      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="dateToPicker" placeholder="Choose a date" [formControl]="dateTo" />
        <mat-datepicker-toggle matIconSuffix [for]="dateToPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateToPicker>
        </mat-datepicker>
      </mat-form-field>


      <section style="display: inline-flex">
        <button class="runReportButton" (click)="runReportClick()">Search<mat-icon>arrow_forward</mat-icon></button>
      </section>
    </div>
    <div class="filter-btn-wrapper">
      <button mat-button class="filter-btn" (click)="onOpenFilter(FilterEnum.scheduleFilter)">
        <div class="filter-icon-wrap">
          <div class="filter-icon"></div>
          Show Filters
        </div>
      </button>
    </div>


  </div>




  <div style="overflow-x:auto;" class="mat-elevation-z2" *ngIf="isMinWidth(760)">
    <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">

      <ng-container *ngFor="let column of displayedColumns">
        <ng-container [matColumnDef]="column.name" id="test">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <!-- {{ row[column.name] }} -->
            <ng-container *ngIf="column.name !== 'actions'">
              <ng-container *ngIf="column.name === 'loadETD'">
                {{ row[column.name] | date: 'dd MMM yyyy' }}
              </ng-container>
              <ng-container *ngIf="column.name === 'stackEnd'">
                {{ row[column.name] | date: 'dd MMM yyyy' }}
              </ng-container>
              <ng-container *ngIf="column.name !== 'loadETD'&& column.name !== 'stackEnd'">
                {{ row[column.name] }}
              </ng-container>
            </ng-container>

            <!--Add a button for the 'actions' column-->
            <ng-container *ngIf="column.name === 'actions'">
              <button class="plannedRouteButton" color="primary" (click)="viewDetails(row,detailView.scheduleDetail)">
                Planned Route<mat-icon>arrow_forward</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="getExtractedColumns(displayedColumns)"></tr>
      <tr mat-row *matRowDef="let row; columns: getExtractedColumns(displayedColumns);"></tr>
    </table>

    <mat-divider></mat-divider>

    <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
    </mat-paginator>
  </div>
  <!-- Cards for smaller screens -->
  <div *ngIf="!isMinWidth(760)">
    <div *ngFor="let row of dataSource.data" class="card" id="mobileCard">
      <ng-container *ngFor="let column of displayedColumns">
        <div *ngIf="column.name !== 'actions'">
          <ng-container *ngIf="column.name === 'loadETD'">
            <strong>{{ column.header }} |</strong>{{getValueForRowColumn(row, column.name) | date: 'dd MMM yyyy'}}
          </ng-container>
          <ng-container *ngIf="column.name === 'stackEnd'">
            <strong>{{ column.header }} |</strong>{{getValueForRowColumn(row, column.name) | date: 'dd MMM yyyy'}}
          </ng-container>
          <ng-container *ngIf="column.name !== 'loadETD'&& column.name !== 'stackEnd'">
            <strong>{{ column.header }} |</strong> {{getValueForRowColumn(row, column.name)}}
          </ng-container>

        </div>
      </ng-container>
      <button class="plannedRouteButton" color="primary" (click)="viewDetails(row,detailView.scheduleDetail)">
        Planned Route <mat-icon>arrow_right_alt</mat-icon>
      </button>
    </div>
  </div>
</div>
