import { OnInit, AfterViewInit, Component, ViewChild, inject, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Alert } from '../models/alerts/alerts';
import { AlertService } from '../service/alerts/alert.service';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { ColumnSetup } from '../models/column-setup';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { Subject } from 'rxjs';
import { takeUntil, catchError, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { BookingModel } from '../models/booking-data-model';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FilterEnum } from '../models/filter-enum';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  @Output() openFilter = new EventEmitter<string>();
  isCustUser: boolean = false;

  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<Alert>;
  alertDataSource = new MatTableDataSource<Alert>();
  isLoading = true;

  currentFilter: DataFilter = new DataFilter();
  filter!: FilterData;
  filterParameter!: FilterData;
  filterId: number = 0;
  filterData: FilterData = {};
  FilterEnum = FilterEnum;
  savedFilters: DataFilter[] = [];
  SavedFilterName: string = '';
  selectedButton = "";

  detailView = detailView;
  dateFrom = new FormControl();
  dateTo = new FormControl();
  startDate?: Date = new Date;
  endDate?: Date = new Date;
 
  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];

  constructor(private alertDataService: AlertService, private communicationService: CommunicationService) { }

  ngOnInit(): void {
    this.updateCurrentFilter(0, '', {});

    this.alertDataService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.SavedFilterName = t;
          this.filterData = {};
          this.loadSavedFilters();
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });



    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;

    this.loadSavedFilters();
    this.updateData()

    this.alertDataService.filterData$.subscribe((value) => {
      this.filter = value;

      this.loadSavedFilters();
      this.updateData(this.filter);
    });


    this.alertDataService.sendActiveFilterToList$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (filterReceived) => {
          this.updateCurrentFilter(filterReceived.id, filterReceived.filterName, filterReceived.filter)
          this.updateData(this.currentFilter.filter);
        }
      });

  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'documentStatusColor', header: '', type: 'string', visible: true },
      { name: 'customer', header: 'Customer', type: 'string', visible:!this.isCustUser },
      { name: 'notification', header: 'Alert Message', type: 'string', visible: true },
      { name: 'alertDate', header: 'Date of Alert', type: 'date', visible: true },
      { name: 'actions', header: 'Actions', type: 'string', visible: true },
      { name: 'isRead', header: '', type: 'string', visible: false }
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  updateData(filterData?: FilterData): void {
    this.alertDataService.getAlerts(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value).subscribe(data => {
      this.configureColumns();
      this.alertDataSource.sort = this.sort;
      this.alertDataSource.paginator = this.paginator;
      this.alertDataSource.data = data;
      this.isLoading = false;
    });
  }

  loadSavedFilters() {
    this.alertDataService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.currentFilter.filterName !== "") {
            let filterId = this.getFilterIdByName(this.currentFilter.filterName);
            this.updateCurrentFilter(filterId, this.currentFilter.filterName, this.getSavedFilterFromId(filterId));
            this.updateData();
          }
        }
      });
  }

  getBackgroundColor(isRead: boolean): string {
    switch (isRead) {
      case false:
        return '#DC6868';
      case true:
        return '#FFD34F';
      default:
        return '#C2C2C2';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  selectButton(button: string) {
    if (this.selectedButton === button) {
      this.selectedButton = '';
      this.applyStatusFilter('');
    } else {
      this.selectedButton = button;
      this.applyStatusFilter(button);
    }
  }

  applyStatusFilter(filterValue: string) {
    if (!filterValue) {
      this.alertDataSource.filter = '';
    } else {
      this.alertDataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  onOpenFilter(filter: FilterEnum) {
    this.communicationService.toggleFilter(filter);
    this.sendingActiveFilter();
  }

  runReportClick(): void {
    this.updateData(this.filter)
    this.alertDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
  }

  sendingActiveFilter() {
    this.alertDataService.sendingActiveFilterToFilter(this.currentFilter)
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  viewDetails(row: Alert): void {
    console.log('Viewing details for:', row);
    this.communicationService.toggleDetailView(row.detailsView, row.detailsTab);
    this.communicationService.getDetailViewParameter(row.reference.toString())
    this.openDetailDrawer.emit();
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.currentFilter.id === filterId) {
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.updateCurrentFilter(filterId, this.getSavedFilterNameFromId(filterId), this.getSavedFilterFromId(filterId))
    }
    this.updateData(this.currentFilter.filter)
  }

  filterByStatus(status: string) {
    this.alertDataSource.filterPredicate = (data: Alert, filter: string) => data.templateGroup === filter;
    this.alertDataSource.filter = status;
  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  filterByDateRange(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    this.alertDataSource.filterPredicate = (data: Alert, filter: string) => {
      const date = new Date(data.alertDate);
      return date >= start && date <= end;
    };
    this.alertDataSource.filter = `${startDate}-${endDate}`;
  }

  markAsRead(rowId: number) {
    this.alertDataService.updateAlertStatus(rowId).subscribe(_ => {
      this.updateData();
    })
  }

  //<---------------------------------------->
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
