import { Component, OnInit} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DocumentService } from '../service/documents/document.service';
import { IDocument, Document } from '../models/documents/document';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { ColumnSetup } from '../models/column-setup'
import { MatDialog} from '@angular/material/dialog';
import { DocumentRejectComponent } from '../dialog/document-reject/document-reject.component'
import { InformationComponent } from '../dialog/information/information.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { saveAs } from 'file-saver';
import { FilterEnum } from '../models/filter-enum';


@Component({
  selector: 'app-document-management',
  templateUrl: './document-management.component.html',
  styleUrl: './document-management.component.css'
})


export class DocumentManagementComponent implements OnInit {

  dataSourceApproved = new MatTableDataSource();
  dataSourcAwaitingAproval = new MatTableDataSource();
  dataSourceResubmitted = new MatTableDataSource();
  dataSourceRejected = new MatTableDataSource();
  documentList: IDocument[] = [];

  private documentParameter: string = '';

  displayedColumns: ColumnSetup[] = [
    { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: false },
    { name: 'documentType', header: 'Document Type', type: 'string', visible: true },
    { name: 'documentReasonCode', header: 'Reason Code', type: 'string', visible: false },
    { name: 'documentReasonDetail', header: 'Reason Detail', type: 'string', visible: false },
    { name: 'reject', header: 'Action', type: 'button', visible: true },
    { name: 'download', header: 'Action', type: 'button', visible: true }
  ];

  displayedColumnsWaiting: ColumnSetup[] = [
    { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: false },
    { name: 'documentType', header: 'Document Type', type: 'string', visible: true },
    { name: 'documentReasonCode', header: 'Reason Code', type: 'string', visible: false },
    { name: 'documentReasonDetail', header: 'Reason Detail', type: 'string', visible: false },
    { name: 'approve', header: 'Action', type: 'button', visible: true },
    { name: 'reject', header: 'Action', type: 'button', visible: true },
    { name: 'download', header: 'Action', type: 'button', visible: true }
  ];

  displayedColumnsResubmitted: ColumnSetup[] = [
    { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: false },
    { name: 'documentType', header: 'Document Type', type: 'string', visible: true },
    { name: 'documentReasonCode', header: 'Reason Code', type: 'string', visible: false },
    { name: 'documentReasonDetail', header: 'Reason Detail', type: 'string', visible: false },
    { name: 'approve', header: 'Action', type: 'button', visible: true },
    { name: 'reject', header: 'Action', type: 'button', visible: true },
    { name: 'download', header: 'Action', type: 'button', visible: true }
  ];

  displayedColumnsRejected: ColumnSetup[] = [
    { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: false },
    { name: 'documentType', header: 'Document Type', type: 'string', visible: true },
    { name: 'documentReasonCode', header: 'Reason Code', type: 'string', visible: true },
    { name: 'documentReasonDetail', header: 'Reason Detail', type: 'string', visible: false },
    { name: 'reasonDetail', header: 'Action', type: 'button', visible: true },
    { name: 'download', header: 'Action', type: 'button', visible: true }
  ];

  constructor(private documentService: DocumentService, private communicationService: CommunicationService, private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { this.documentParameter = param });
    this.getDocuments(this.documentParameter);
  }

   getDocuments(documentParam: string): void {
    this.documentService.getDocumentsForGRRef(documentParam).subscribe(data => 
    {
      this.documentList = data;
      this.dataSourceApproved.data = this.documentList.filter(status => status.documentApprovalStatus === 'Approved')
      this.dataSourcAwaitingAproval.data = this.documentList.filter(status => status.documentApprovalStatus === 'AwaitingApproval')
      this.dataSourceResubmitted.data = this.documentList.filter(status => status.documentApprovalStatus === 'Resubmitted')
      this.dataSourceRejected.data = this.documentList.filter(status => status.documentApprovalStatus === 'Rejected')

    })
  }

  rejectDocument(row: any): void {
    const dialogRef = this.dialog.open(DocumentRejectComponent, {
      width: '300px',
      height: '350px',
      data: { documentApprovalStatus: 'Rejected', documentReasonCode: '', documentReasonDetail: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const rejectionData = {
          documentApprovalStatus: 'Rejected',
          documentReasonCode: result.reasonCode,
          documentReasonDetail: result.reasonDetail,
        };
        this.documentService.updateDocumentStatus(row['entryNo'], JSON.stringify(rejectionData))
          .pipe(
          catchError(error => {
            console.error('Error updating document status:', error);
            this.showInformation('Document rejection Failed', error);
            return of(null); // Return a fallback value or an empty observable
          })
        )
          .subscribe(response => {
            if (response) {
              console.log('Document rejected:', response);
              this.showInformation('Document Status was updated', '')
              this.documentService.getDocumentsForGRRef(row['grReference']).subscribe(data => {
                this.documentList = data;
                this.dataSourceApproved.data = this.documentList.filter(status => status.documentApprovalStatus === 'Approved')
                this.dataSourcAwaitingAproval.data = this.documentList.filter(status => status.documentApprovalStatus === 'AwaitingApproval')
                this.dataSourceResubmitted.data = this.documentList.filter(status => status.documentApprovalStatus === 'Resubmitted')
                this.dataSourceRejected.data = this.documentList.filter(status => status.documentApprovalStatus === 'Rejected')

              })

            }

        });
      }
    });
  }

  downloadDocument(row: any): void {
    this.documentService.downloadDocumentWithSystemId(row['documentAttachmentSystemId'])
      .pipe(
        catchError(error => {
          console.error('Error updating document status:', error);
          this.showInformation('Download Failed', error);
          return of(null); // Return a fallback value or an empty observable
        })
      )
 
      .subscribe(blob => {
        if (blob) {
          saveAs(blob, row['attachmentFilename'] + '.' + row['attachmentType']);
        } })

  }

  approveDocument(row: any): void {
    const approved = {
      documentApprovalStatus: 'Approved'
    };

    this.documentService.updateDocumentStatus(row['entryNo'], JSON.stringify(approved))
      .pipe(
        catchError(error => {
          console.error('Error updating document status:', error);
          this.showInformation('Document Approval Failed', error);
          return of(null); // Return a fallback value or an empty observable
        })
      )
      .subscribe(response => {
        if (response) {
          this.showInformation('Document Status updated to Approved', '')
          this.documentService.getDocumentsForGRRef(row['grReference']).subscribe(data => {
            this.documentList = data;
            this.dataSourceApproved.data = this.documentList.filter(status => status.documentApprovalStatus === 'Approved')
            this.dataSourcAwaitingAproval.data = this.documentList.filter(status => status.documentApprovalStatus === 'AwaitingApproval')
            this.dataSourceResubmitted.data = this.documentList.filter(status => status.documentApprovalStatus === 'Resubmitted')
            this.dataSourceRejected.data = this.documentList.filter(status => status.documentApprovalStatus === 'Rejected')

          })
        }

      });
  }

  showDetail(row: any): void {
    //console.log('Viewing details for:', row);
    //this.communicationService.toggleDetailView(detailViewToOpen);
    //this.communicationService.getDetailViewParameter(row['grReference'])
    alert(row['documentReasonDetail']);
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: ColumnSetup[]) {
    return columns
      .filter(col => col.visible)
      .map(col => col.name);
  }

  showInformation(result: any, message: string | null): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      width: '300px',
      height: '250px',
      data: { resultStatus: result, showMessage: message },
    });
  }

  showError(message: string): void {
    // Implement your error display logic here, e.g., showing a snackbar or dialog
    console.error(message);
    this.showInformation('Document rejection Failed', message)
  }

  onOpenFilter(openFiler: FilterEnum) {
    this.communicationService.toggleFilter(openFiler);
  }
}


