export enum detailView{
    none,
    scheduleDetail,
    bookingDetail,
    documentDetail,
    shipmentDetail
}
export enum detailTabbedViewEnum {
    none,
    trackingTab,
    documentTab,
    alertTab,
    detailsTab
}

export interface DetailedViewState {
    detailView: detailView;
    detailTabbedViewEnum: detailTabbedViewEnum;
  }
