import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { FormControl } from '@angular/forms';
import { FilterData } from '../models/list-model';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { BookingService } from '../service/booking/booking.service';
import { FilterEnum } from '../models/filter-enum';
import { Subject } from 'rxjs';
import { takeUntil, catchError, distinctUntilChanged, skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AddBookingEnum, BookingDataTransferModel } from '../models/booking-data-model';
import { MAT_DATE_FORMATS } from '@angular/material/core';


// Custom date formats for date picker
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'dd/MM/yyyy',
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'dd/MM/yyyy',
    monthYearA11yLabel: 'MM yyyy',
  },
};

@Component({
  selector: 'app-booking-management',
  templateUrl: './booking-management.component.html',
  styleUrl: './booking-management.component.css',
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS}
  ]
})


export class BookingManagementComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  @Output() openFilter = new EventEmitter<string>();
  selectedButton="";
  dateFrom = new FormControl(); 
  dateTo = new FormControl();
  query: string[][] = [];
  filterData: FilterData = {};
  FilterEnum = FilterEnum;
  savedFilters: DataFilter[] = [];
  selectedFilterId: number = 0;
  SavedFilterName: string = '';
  currentFilter: DataFilter = new DataFilter();
  bookingTransfer: BookingDataTransferModel = new BookingDataTransferModel();

  constructor(private bookingDataService: BookingService, private communicationService: CommunicationService, private router: Router) {}

  ngOnInit(): void {
    this.loadSavedFilters();

    this.bookingDataService.filterNameSaved$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (t) => {
          this.SavedFilterName = t;
          this.filterData = {};
          this.loadSavedFilters();
        },
        error: (error) => {
          console.error('Error handling filter name saved event', error);
          window.alert('Failed to handle filter name saved event. Please try again later.');
        }
      });

    this.bookingDataService.filterData$
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe({
        next: (value) => {
          this.filterData = value;
          this.selectedFilterId = 0;
          this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.filterData)
          this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
        },
        error: (error) => {
          console.error('Error receiveing filter data', error);
          window.alert('Error receiveing filter data');
        }
      });
  }

  loadSavedFilters() {
    this.bookingDataService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.savedFilters = data;
          if (this.SavedFilterName !== "") {
            this.changeSelectedButton(this.SavedFilterName);
            this.selectedFilterId = this.getFilterIdByName(this.SavedFilterName);
            this.updateCurrentFilter(this.selectedFilterId, this.SavedFilterName, this.getSavedFilterFromId(this.selectedFilterId));
            this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
          }
        }
      });
  }

  private applySavedFilter(filterName: string): void {
      this.changeSelectedButton(filterName);
      this.bookingDataService.sendingFilterId(this.selectedFilterId);
    }


  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }

  onOpenFilter(filter: FilterEnum) {
    this.communicationService.toggleFilter(filter);
    this.sendingActiveFilter();
  }

  runReportClick(): void {
    this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
  }
  
  selectButton(button: string) {
    if (this.selectedButton === button) {
      this.selectedButton = '';
      this.bookingDataService.toggleStatusFilter('');
    } else {
      this.selectedButton = button;
      this.bookingDataService.toggleStatusFilter(button);
    }    
  }

  applyFilter(filterId: number): void {
    this.filterData = {};

    if (this.selectedFilterId === filterId) {
      this.selectedFilterId = 0;
      this.updateCurrentFilter(0, '', {});
    }
    else {
      this.selectedFilterId = filterId;
      this.updateCurrentFilter(this.selectedFilterId, this.getSavedFilterNameFromId(this.selectedFilterId), this.getSavedFilterFromId(this.selectedFilterId))
    }    
    this.bookingDataService.sendingFilterTransferModel(new FilterTransferModel(this.currentFilter.id, this.currentFilter.filter, this.dateFrom.value, this.dateTo.value));
  }

  changeSelectedButton(newSavedFilterName: string): void {
    this.selectedFilterId = this.getFilterIdByName(newSavedFilterName);
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;
  }

  getSavedFilterNameFromId(filterId: number): string {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filterName : '';
  }

  getSavedFilterFromId(filterId: number): FilterData {
    const filter = this.savedFilters.find(f => f.id === filterId);
    return filter ? filter.filter : {};
  }

  getFilterIdByName(filterName: string): number {
    const filter = this.savedFilters.find(f => f.filterName === filterName);
    return filter ? filter.id : 0;
  }

  sendingActiveFilter() {
   this.bookingDataService.sendingActiveFilterToFilter(this.currentFilter)
  }

  navigateToAddBooking() {
    this.bookingTransfer = {
      appBookingCode: null,
      appBookingLineNumber: null,
      scheduleCode: null,
      loadPortCode: null,
      dischargePortCode: null,
      carrierCode: null,
      bookingType: AddBookingEnum.newBooking
    }
    
    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
  }

  /*<------------------------------------------------------------------->*/
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
