<div class="filter-container">
  <div class="filter-field-wrapper saved-view-name">
    <div class="filter-help" *ngIf="isSavedFilterNameEmpty"> 
      <img class="info-icon" alt="Info Icon" src="../../assets/Info.svg"> 
      <div class="help-text"> Enter a unique name to save your filters </div>
    </div>
    <mat-form-field>
      <mat-label>Saved Filter View Name</mat-label>
      <input matInput [formControl]="savedFilterName">
    </mat-form-field>
    <div class="action-buttons">
      <button mat-button class="small-button delete" (click)="deleteFilter()" *ngIf="datafilter.id !== 0">Delete View</button>
      <button mat-button class="small-button blue" (click)="save()" *ngIf="!isSavedFilterNameEmpty">Save View</button>
    </div>
  </div>

  <div class="filter-field-wrapper">
    
    <mat-form-field>
      <mat-label>GR Ref No.</mat-label>
      <input matInput [formControl]="grRefNos">
    </mat-form-field>

    <ng-container *ngIf="!isCustUser">
      <mat-form-field *ngIf="customers.value">
        <mat-label>Customer</mat-label>
        <mat-select [formControl]="customers" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{customers.value.length > 0 ? customers.value[0].code : ''}}
            <span *ngIf="customers.value[0]  && customers.value.length > 1" class="example-additional-selection">
              (+{{(customers.value.length || 0) - 1}} {{customers.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(customers)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
            {{customer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="loadPorts.value">
      <mat-label>Load Ports   </mat-label>
      <mat-select [formControl]="loadPorts" multiple #multiSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="loadPortsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Load port not found" [alwaysRestoreSelectedOptionsMulti]="true"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{loadPorts.value.length > 0 ? loadPorts.value[0].code : ''}}
          <span *ngIf="loadPorts.value[0]  && loadPorts.value.length > 1" class="example-additional-selection">
            (+{{(loadPorts.value.length || 0) - 1}} {{loadPorts.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(loadPorts)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
        </mat-select-trigger>
       <mat-option *ngFor="let loadPort of filteredLoadPorts | async"  [value]="loadPort"  >
        {{loadPort.name}}
       </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="markets.value">
      <mat-label>Market</mat-label>
      <mat-select [formControl]="markets" multiple #multiSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="marketsMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Market not found"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{markets.value.length > 0 ? markets.value[0].code : ''}}
          <span *ngIf="markets.value[0]  && markets.value.length > 1" class="example-additional-selection">
            (+{{(markets.value.length || 0) - 1}} {{markets.value.length === 2 ? 'other' : 'others'}})
          </span>
          <button class="clear-filter"><mat-icon svgIcon="backspace"></mat-icon></button>
        </mat-select-trigger>
        <mat-option *ngFor="let market of filteredMarkets | async" [value]="market">
          {{market.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="carriers.value">
      <mat-label>All Carriers</mat-label>
      <mat-select [formControl]="carriers" multiple #multiSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="carriersMultiFilter" placeholderLabel="Search" noEntriesFoundLabel="Carrier Not Found"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{carriers.value.length > 0 ? carriers.value[0].code : ''}}
          <span *ngIf="carriers.value[0]  && carriers.value.length > 1" class="example-additional-selection">
            (+{{(carriers.value.length || 0) - 1}} {{carriers.value.length === 2 ? 'other' : 'others'}})
          </span>
          <button class="clear-filter" (click)="clearLookupFilter(carriers)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-select-trigger>
        <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">
          {{carrier.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Late Stacks</mat-label>
      <mat-select [formControl]="latestacks">
        <mat-select-trigger>
          {{latestacks.value || ''}}
        </mat-select-trigger>
        <mat-option>None</mat-option>
        @for (latestack of bookingDatalatestack; track latestack) {
        <mat-option [value]="latestack">{{latestack}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <div class="action-buttons">
      <button mat-button class="small-button" (click)="applyFilters()">Apply Filters</button>

      <button class="small-button soft-blue" (click)="clearAllFilters()">
        Clear All Filters
      </button>
    </div>

  </div>
</div>
