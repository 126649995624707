import { AlertNotificationModel } from "./alert-notification-model";
import { AlertSubscriptionModel } from "./alert-subscription-model";

export interface IAlertSubscriptionInputModel {
  notificationModel: AlertNotificationModel,
  templates: AlertSubscriptionModel[];
}

export class AlertSubscriptionInputModel implements IAlertSubscriptionInputModel {
  notificationModel: AlertNotificationModel = new AlertNotificationModel;
  templates: AlertSubscriptionModel[] = [];
}
