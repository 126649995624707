import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { FilterData, UpdateData, } from '../../models/list-model';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { IDocument, Document } from '../../models/documents/document';
import { ColumnSetup } from '../../models/column-setup'
import { saveAs } from 'file-saver'
import { ILookupModel, LookupModel } from '../../models/lookup-model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private apiUrl = environment.apiUrl;
  private jsonData: string | undefined;

  private filterDataTransfer = new BehaviorSubject<FilterData>({});
  filterData$ = this.filterDataTransfer.asObservable();

  private statusFilterSubject = new BehaviorSubject<string | null>(null);
  statusFilterToggle$ = this.statusFilterSubject.asObservable();

  private sendFilter = new BehaviorSubject<string | null>(null);
  sendFilter$ = this.sendFilter.asObservable();

  private filterNameSaved = new BehaviorSubject('');
  filterNameSaved$ = this.filterNameSaved.asObservable();

  private sendFilterId = new BehaviorSubject<number>(-1);
  sendFilterId$ = this.sendFilterId.asObservable()

  private sendActiveFilterToFilter = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilter$ = this.sendActiveFilterToFilter.asObservable();

  private sendActiveFilterToList = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToList$ = this.sendActiveFilterToList.asObservable();

  constructor(private http: HttpClient) { }


  getDocumentList(filterId: number, filterData?: FilterData): Observable<IDocument[]> {
    var url = this.apiUrl +  '/Document/GetDocuments';
    let params = new HttpParams();


    if (filterData && Object.keys(filterData).length > 0) {

      this.jsonData = this.convertFilterDataToJsonString(filterData);

      url = url + '?filter=' + this.jsonData;
    }
    else if (filterId && filterId > 0) {
      url = url + '?filterId=' + filterId.toString();
    }

    return this.http.get<IDocument[]>(url)

  }

  getDocumentListForManagement(filterData: FilterData): Observable<IDocument[] | string> {
    var url = this.apiUrl +  '/Document/GetDocumentForManagement';

    if (filterData) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        this.jsonData = JSON.stringify(filteredData);

        console.log(url + "?filter=" + this.jsonData);
      }
    }

    if (this.jsonData != undefined) {
      url = url + "?filter=" + this.jsonData
    }

    return this.http.get<IDocument[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  getDocumentsForGRRef(grRef: string | undefined): Observable<IDocument[]> {
    const url = this.apiUrl +  '/Document/GetDocumentsForGRRef?grref=' + grRef;
    return this.http.get<IDocument[]>(url);
  }

  downloadDocumentWithSystemId(documentSystemId: string): Observable<Blob | string> {
    var url = this.apiUrl +  '/Document/DownloadDocument';

    if (documentSystemId) {
      url = url + "?documentAttachmentSystemId=" + documentSystemId
    }

    return this.http.get(url, {responseType: 'blob'}).pipe(
      catchError(this.handleError)
    );
  };

  updateDocumentStatus(entryNo: number, updateData: string): Observable<any> {
    let url = this.apiUrl +  '/Document/UpdateDocumentStatus';

    if (updateData && entryNo) {
      url = url + "?entryNo=" + entryNo + "&documentChange=" + updateData
    }

    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<string> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Backend error
      errorMessage = `Error Code status: ${error.status} ${error.statusText}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  getFilters(): Observable<DataFilter[]> {
    const url = this.apiUrl + '/Document/GetFilters';
    return this.http.get<DataFilter[]>(url);
  }

  saveDocumentFilter(filterName: string, filterData?: FilterData): Observable<any> {
    const url = this.apiUrl + '/Document/SaveFilter?filterName=' + filterName;

    let body = {};
    if (filterData) {
      body = this.convertFilterDataToJsonString(filterData)
    }

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );

  }

  deleteDocumentFilter(filterId: number): Observable<any> {
    const url = this.apiUrl + '/Document/DeleteFilter?filterId=' + filterId;

    return this.http.delete<string>(url)
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );

  }

  transferFilterData(filterData: FilterData) {
    this.filterDataTransfer.next(filterData);
    console.log("service filter data", filterData);
  }

  toggleStatusFilter(statusFilterName: string) {
    this.statusFilterSubject.next(statusFilterName);
  }

  sendFilterSetupToFilter(filter: string) {
    this.sendFilter.next(filter);
  }

  filterNameSavedSend(savedFilterName: string) {
    console.log('FilterSaved')

    this.filterNameSaved.next(savedFilterName);
  }

  sendingFilterId(filterId: number) {
    this.sendFilterId.next(filterId);
  }

  sendingActiveFilterToFilter(filter: DataFilter) {
    this.sendActiveFilterToFilter.next(filter);
  }

  sendingActiveFilterToList(filter: DataFilter) {
    this.sendActiveFilterToList.next(filter);
  }


  private convertFilterDataToJsonString(filterData: FilterData): string {
    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        return JSON.stringify(filteredData);
      }
    }
    return '';
  }

}



