<div class="alert-management">
    <div class="title-wrapper">
        <h4>Select the type of alerts you want to recieve</h4>
    </div>

     <mat-dialog-content class="alert-dialog">
        <div class="alert-subscription-wrapper">
            <div class="alert-group-title">
                <h5>Ocean</h5>
            </div>
            
            <div class="alert-subscription-item" *ngFor="let alert of oceanAlerts; let i = index">
                <p>{{ alert.name }}</p>
                <mat-slide-toggle  [(ngModel)]="alert.toggled"
                (change)="onToggleChange(i, $event)" aria-label="Toggle alert"></mat-slide-toggle>

            </div>
        </div>


    </mat-dialog-content>

    <mat-dialog-actions class="close-dialog" align="end">
        <button class="close-button" mat-dialog-close><mat-icon svgIcon="CancelIcon"></mat-icon></button>
    </mat-dialog-actions>

</div>
