export interface IUserInfoModel {
  userId: string;
  userFullName: string;
  userOrganisation: string;
  userEmail: string;
  userPhone: string;
  accountUrl: string;
  logoutUrl: string;
  isSingleCustomer: boolean;
}

export class UserInfoModel implements IUserInfoModel {
  userId: string = '';
  userFullName: string = '';
  userOrganisation: string = '';
  userEmail: string = '';
  userPhone: string = '';
  accountUrl: string = '';
  logoutUrl: string = '';
  isSingleCustomer: boolean = false;
}
