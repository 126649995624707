import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../../dialog/information/information.component'


@Component({
  selector: 'app-create-filter',
  templateUrl: './create-filter.component.html',
  styleUrl: './create-filter.component.css'
})
export class CreateFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    console.log(this.data)
    if (!this.data.filterName) {
      //alert('Please select a reason code.');
      this.showInformation('Input Error', 'Please input a filter name.')

    } else {
      this.dialogRef.close(this.data);
    }
  }

  showInformation(result: any, message: string): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      width: '300px',
      height: '250px',
      data: { resultStatus: result, showMessage: message },
    });
  }
}
