import { Component, OnInit, inject } from '@angular/core';
import {trigger,style, transition, animate} from'@angular/animations';
import { RightDrawer } from '../models/right-drawer-enum';
import { MatDialog } from '@angular/material/dialog';
import { AlertManagementComponent } from '../alert-management/alert-management.component'; // Adjust path as necessary
import { AlertService } from '../service/alerts/alert.service';
import { Alert } from '../models/alerts/alerts';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-alert-sidenav',
  templateUrl: './alert-sidenav.component.html',
  styleUrls: ['./alert-sidenav.component.css'],
  animations: [
    trigger('cardAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-50px)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class AlertSidenavComponent implements OnInit {

  private app = inject(AppComponent); 
  alertData: Alert[] = [];
  rightNav: RightDrawer = RightDrawer.alertSideNav;
  RightDrawer = RightDrawer;
  dialog = inject(MatDialog);

  constructor(private alertDataService: AlertService, dialog: MatDialog) { }

  ngOnInit(): void {
    this.getUnreadAlerts();
  
    this.app.wsMessage.subscribe(data => {
      if (data != null) {
          this.getUnreadAlerts();
      }
    });
  }
  

  getUnreadAlerts(): void {
    this.alertDataService.getUnReadAlerts().subscribe(
      (alertNotifications) => {
        this.alertData = alertNotifications as unknown as Alert[];
        console.log(this.alertData);
       }
    );
  }

  removeCard(index: number): void {
    this.alertData.splice(index, 1);
  }

  openToggleDialog(): void {
    this.dialog.open(AlertManagementComponent, {
      width: '600px', // Set the width as needed
      data: { /* pass data if needed */ } 
    });
  }

}
