<div class="booking">
    <div class="title-header">
        <div class="detail-header"><h3>{{booking.clientName}} - {{booking.grRefNo}} </h3></div>
        <div class="booking-status">Booking Status: <span class="booking-status-ul">{{booking.appBookingStatus}}</span> </div>
    </div>

    <div class="divider-line"></div>

    <mat-card *ngFor="let line of booking.bookingLines" class="booking-detail-card">
        <mat-card-header class="booking-detail-card-header">
            Booking lines {{line.lineNo}}
        </mat-card-header>
        <mat-card-content class="booking-detail-content">
            <div class="booking-line">
                <div class="detail-section-a">
                    <div>Booking ref: {{line.bookingRef}}</div>
                    <div>Client ref: {{line.clientRef}}</div>
                    <div>Container status: {{line.containerStatus}}</div>
                    <div>App Booking status: {{line.appBookingStatus}}</div>
                    <div>Rejection reason: {{line.rejectionReason}}</div>
                    <div>Discharge port: {{line.dischargePort}}</div>
                    <div>Final destination: {{line.finalDestination}}</div>
                    <div>Confirmed pack date: {{line.confirmedPackDate | date}}</div>
                </div>

                <div class="detail-section-a">
                    <div>Commodity: {{line.commodity}}</div>
                    <div>Loading point 1: {{line.loadingPoint1}}</div>
                    <div>Loading point 2: {{line.loadingPoint2}}</div>
                    <div>Temperature: {{line.temperature}} °C</div>
                    <div>Quantity: {{line.quantity}}</div>
                    <div>Line no: {{line.lineNo}}</div>
                    <div>Late stack request: {{line.lateStackRequest ? 'Yes' : 'No'}}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="detailed-btn-download-group">
        <button class="detailed-view-btn-download" >
            Download full booking <mat-icon>download</mat-icon>
          </button>
    
          <button class="detailed-view-btn-download" >
            Per load point <mat-icon>download</mat-icon>
          </button>
    </div>


</div>
