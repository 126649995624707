
<div class="mat-elevation-z2  custom-card-radius" *ngIf="isMinWidth(760)">
  <table mat-table class="full-width-table" [dataSource]="bookingDataSource" matSort aria-label="Elements">
    <ng-container *ngFor="let column of displayedColumns">
      <ng-container [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!column.visible">
          {{ column.header }}
        </th>
        <td mat-cell *matCellDef="let row" [hidden]="!column.visible"
            [ngStyle]="column.name === 'appBookingStatusColor' ? {'background-color': getBackgroundColor(row['appBookingStatus'])} : {}">

          <ng-container *ngIf="column.name !== 'actions'">
            <ng-container *ngIf="column.name === 'sailDate'">
              {{row[column.name] | date: 'dd MMM yyyy' }}
            </ng-container>
            <ng-container *ngIf="column.name !== 'sailDate' && column.name !== 'appBookingStatusColor'">
              {{ row[column.name] }}
            </ng-container>
            <ng-container *ngIf="column.name === 'appBookingStatusColor'">
            </ng-container>
          </ng-container>
          <ng-container *ngIf="column.name === 'actions'">
            <button class="detailed-view-btn" (click)="viewDetails(row, detailView.bookingDetail)">
              View Details <mat-icon>arrow_right_alt</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="extractedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: extractedColumns;"></tr>
  </table>

  <mat-paginator #paginator
                 [length]="bookingDataSource.data.length"
                 [pageIndex]="0"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 20]"
                 aria-label="Select page">
  </mat-paginator>
</div>
  
  <div *ngIf="!isMinWidth(760)">
    <div *ngFor="let row of bookingDataSource.filteredData" class="card" id="mobileCard" [ngStyle]="{'border-left-color': getBackgroundColor(row.appBookingStatus)}">
      <ng-container *ngFor="let column of displayedColumns">
        <div *ngIf="column.name !== 'actions'&& column.name !== 'appBookingStatusColor'">
          <strong>{{ column.header }} <span class="card-line">|</span></strong>
          <ng-container *ngIf="column.name === 'sailDate'">
            {{ getValueForRowColumn(row, column.name) | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="column.name !== 'sailDate' && column.name !== 'appBookingStatusColor'">
            {{ getValueForRowColumn(row, column.name) }}
          </ng-container>
        </div>
      </ng-container>
      <button class="detailed-view-btn" color="primary" (click)="viewDetails(row, detailView.bookingDetail)">
        View Details <mat-icon>arrow_right_alt</mat-icon>
      </button>
    </div>
  </div>
  
  
