import { KeycloakService, KeycloakEventType } from "keycloak-angular";
import { environment } from "../../environments/environment";

export function initializeKeycloak(keycloak: KeycloakService) {
  return () => {

    keycloak.keycloakEvents$.subscribe({
      next(event) {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          keycloak.updateToken(5);
        }
      }
    });

    return keycloak.init({
      config: {
        url: environment.authUrl,
        realm: environment.authRealm,
        clientId: environment.authClient
      },
      initOptions: {
        checkLoginIframe: false,
        onLoad: 'login-required'
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      shouldAddToken: (request) => {
        const { url } = request;
        const tokenPaths = [environment.baseUrl, environment.apiUrl];
        var isValidTokenPath = tokenPaths.some((path) => url.startsWith(path));
        return isValidTokenPath;
      },
      bearerExcludedUrls: []
    });
  }
}
