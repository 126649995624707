import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BookingModel } from '../../models/booking-data-model';
import { FilterData } from '../../models/list-model';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ILookupModel, LookupModel } from '../../models/lookup-model';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle, } from '@angular/material/dialog';
import { InformationComponent } from '../../dialog/information/information.component';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private apiUrl = environment.apiUrl;


  private filterDataTransfer = new BehaviorSubject<FilterData>({});
  filterData$ = this.filterDataTransfer.asObservable();

  private statusFilterSubject = new BehaviorSubject<string | null>(null);
  statusFilterToggle$ = this.statusFilterSubject.asObservable();

  private sendFilter = new BehaviorSubject<string | null>(null);
  sendFilter$ = this.sendFilter.asObservable();

  private filterNameSaved = new BehaviorSubject('');
  filterNameSaved$ = this.filterNameSaved.asObservable();

  private sendFilterId = new BehaviorSubject<number>(-1);
  sendFilterId$ = this.sendFilterId.asObservable()

  private sendActiveFilterToFilter = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilter$ = this.sendActiveFilterToFilter.asObservable();

  private sendFilterTransfer = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  sendFilterTransfer$ = this.sendFilterTransfer.asObservable();

  constructor(private http: HttpClient, private dialog: MatDialog) { }

  getBookings(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): Observable<BookingModel[]> {
    let url = this.apiUrl + '/Booking/GetBookings';
  
    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);
        
         url = url + '?filter=' + jsonData;
      }
    }
    else if (filterId && filterId > 0) {
      url = url + '?filterId=' + filterId.toString();
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }
    return this.http.get<BookingModel[]>(url);
  }

  getSampleBookingByGrRef(grRefNo:string): Observable<BookingModel>{
    const url = this.apiUrl + '/Booking/GetBooking/'+grRefNo;

    return this.http.get<BookingModel>(url);
  } 
 
  getFilters(): Observable<DataFilter[]> {
    const url = this.apiUrl + '/Booking/GetFilters';
    return this.http.get<DataFilter[]>(url);
  }

  saveBookingFilter(filterName: string, filterData?: FilterData): Observable<any> {
    const url = this.apiUrl + '/Booking/SaveFilter?filterName=' + filterName; 

    let body = {};

    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        body = JSON.stringify(filteredData);
      }
    }
    console.log(url)

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );
    
  }

  deleteBookingFilter(filterId: number): Observable<any> {
    const url = this.apiUrl + '/Booking/DeleteFilter?filterId=' + filterId;

    return this.http.delete<string>(url)
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );

  }

  transferFilterData(filterData: FilterData) {
    this.filterDataTransfer.next(filterData);
  }

  toggleStatusFilter(statusFilterName: string){
    this.statusFilterSubject.next(statusFilterName);
  }

  sendFilterSetupToFilter(filter: string) {
    this.sendFilter.next(filter);
  }

  filterNameSavedSend(savedFilterName: string) {
    console.log('FilterSaved')

    this.filterNameSaved.next(savedFilterName);
  }

  sendingFilterId(filterId: number) {
    this.sendFilterId.next(filterId);
  }

  sendingActiveFilterToFilter(filter: DataFilter) {
    this.sendActiveFilterToFilter.next(filter);
  }

  sendingFilterTransferModel(transferModel: FilterTransferModel) {
    this.sendFilterTransfer.next(transferModel);
  }
}
