import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar'; // Import MatSnackBar
import { AlertSubscriptionModel } from '../../models/alerts/alert-subscription-model';

interface GroupedSubscriptions {
  [key: string]: AlertSubscriptionModel[];
}

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  subscriptionList: AlertSubscriptionModel[] = []; // Initialize with the correct type
  groupedSubscriptions: GroupedSubscriptions = {}; // Use the GroupedSubscriptions type

  constructor(
    public dialogRef: MatDialogRef<SubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { subscriptionList: AlertSubscriptionModel[] },
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _snackBar: MatSnackBar // Inject MatSnackBar
  ) {
    // Register SVG icons
    this.matIconRegistry.addSvgIcon(
      'CancelIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/CancelIcon.svg')
    );
    // Add more icons if needed
    this.matIconRegistry.addSvgIcon(
      'SomeOtherIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/SomeOtherIcon.svg')
    );
  }

  ngOnInit() {
    // Initialize subscriptionList from data
    this.subscriptionList = this.data.subscriptionList;

    // Group by transportMode
    this.groupByTransportMode();
  }

  onOk(): void {
    // Return the updated subscription list to the parent component
    this.dialogRef.close(this.subscriptionList);
    this.openSnackBar('Your subscriptions has been updated');
  }

  onCancel(): void {
    // Close the dialog without returning any data
    this.dialogRef.close();
  }

  onReject(): void {
    // Set all subscriptions to not subscribed
    this.subscriptionList.forEach(subscription => subscription.isSubscribed = false);

    // Return the updated subscription list to the parent component
    this.dialogRef.close(this.subscriptionList);

    // Show snack bar notification
    this.openSnackBar('You have unsubscribed to all alerts on this line');
  }

  subscribeToAll(): void {
    this.subscriptionList.forEach(subscription => subscription.isSubscribed = true);

    // Return the updated subscription list to the parent component
    this.dialogRef.close(this.subscriptionList);

    // Show snack bar notification
    this.openSnackBar('You have subscribed to all alerts');
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  groupByTransportMode() {
    this.groupedSubscriptions = this.subscriptionList.reduce((acc: GroupedSubscriptions, subscription) => {
      const { transportMode } = subscription;

      // Initialize array if it does not exist
      if (!acc[transportMode]) {
        acc[transportMode] = [];
      }

      // Push the subscription to the respective group
      acc[transportMode].push(subscription);

      return acc;
    }, {});
  }

  getTransportModes(): string[] {
    return Object.keys(this.groupedSubscriptions);
  }
}
