import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError, map, mergeMap, Observable, of } from 'rxjs';
import { FilterData } from '../../models/list-model';
import { ShipmentModel } from '../../models/shipment/shipment-data-model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataFilter, FilterTransferModel } from '../../models/filter-models';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  private apiUrl = environment.apiUrl;
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private filterDataTransfer = new BehaviorSubject<FilterData>({});
  filterData$ = this.filterDataTransfer.asObservable(); 

  private statusFilterSubject = new BehaviorSubject<string | null>(null);
  statusFilterToggle$ = this.statusFilterSubject.asObservable();

  private sendFilter = new BehaviorSubject<string | null>(null);
  sendFilter$ = this.sendFilter.asObservable();

  private filterNameSaved = new BehaviorSubject('');
  filterNameSaved$ = this.filterNameSaved.asObservable();

  private sendFilterId = new BehaviorSubject<number>(-1);
  sendFilterId$ = this.sendFilterId.asObservable()

  private sendActiveFilterToFilter = new BehaviorSubject<DataFilter>(new DataFilter());
  sendActiveFilterToFilter$ = this.sendActiveFilterToFilter.asObservable();

  private sendFilterTransfer = new BehaviorSubject<FilterTransferModel>(new FilterTransferModel(-1, {}));
  sendFilterTransfer$ = this.sendFilterTransfer.asObservable();

  constructor(private http: HttpClient) { }

  getShipments(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): Observable<ShipmentModel[]> {
    let url = this.apiUrl + '/Shipment/GetShipments';
    let params = new HttpParams();

    if (filterData && Object.keys(filterData).length > 0) {

      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        const jsonData = JSON.stringify(filteredData);
        
         url = url + '?filter=' + jsonData;
      }
    }
    else if (filterId && filterId > 0) {
      url = url + '?filterId=' + filterId.toString();
    }

    if (startDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateFrom=' + startDate.toISOString();
    }

    if (endDate) {
      const contains = url.includes("?");
      url += (contains ? '&' : '?') + 'dateTo=' + endDate.toISOString();
    }
  
    return this.http.get<ShipmentModel[]>(url, { params });
  }

  getShipmentsForGRRef(grRef: string | undefined): Observable<ShipmentModel[]> {
    const url = this.apiUrl +  '/Shipment/GetShipmentsForGRRef?grRef=' + grRef;
    return this.http.get<ShipmentModel[]>(url);
  }

  getFilters(): Observable<DataFilter[]> {
    const url = this.apiUrl + '/Shipment/GetFilters';
    return this.http.get<DataFilter[]>(url);
  }

  transferFilterData(filterData: FilterData) {
    this.filterDataTransfer.next(filterData);
    console.log("service filter data", filterData);
  }

  toggleStatusFilter(statusFilterName: string){
    this.statusFilterSubject.next(statusFilterName);
  }

  sendFilterSetupToFilter(filter: string) {
    this.sendFilter.next(filter);
  }

  filterNameSavedSend(savedFilterName: string) {
    console.log('FilterSaved')

    this.filterNameSaved.next(savedFilterName);
  }

  sendingFilterId(filterId: number) {
    this.sendFilterId.next(filterId);
  }

  sendingActiveFilterToFilter(filter: DataFilter) {
    this.sendActiveFilterToFilter.next(filter);
  }

  sendingFilterTransferModel(transferModel: FilterTransferModel) {
    this.sendFilterTransfer.next(transferModel);
  }

  saveBookingFilter(filterName: string, filterData?: FilterData): Observable<any> {
    const url = this.apiUrl + '/Shipment/SaveFilter?filterName=' + filterName; 

    let body = {};

    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        body = JSON.stringify(filteredData);
      }
    }
    console.log(url)

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        })
      );
    
  }

  deleteShipmentFilter(filterId: number,filterName:string ): Observable<any> {
    const url = this.apiUrl + '/Shipment/DeleteFilter?filterId=' + filterId;

    return this.http.delete<string>(url)
      .pipe(
        catchError(error => {
          // Display the error message as an alert
          window.alert('An error occurred: ' + error.error);
          this.openFailSnackBar('Failed to delete Your filter ' + filterName);
          // Return an observable with a user-facing error message
          return of(`Error: ${error.message}`);
        }),mergeMap(response => {
          if (response === null) {
            this.openSuccessSnackBar('Your filter, '+filterName+ ' has been deleted');
          }
          return of(response);
        })
      );

  }


  openSuccessSnackBar(message:string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
        duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  openFailSnackBar(message:string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackbar-fail']
    });
  }
}
