import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../../dialog/information/information.component'


@Component({
  selector: 'app-document-reject',
  templateUrl: './document-reject.component.html',
  styleUrl: './document-reject.component.css',

})
export class DocumentRejectComponent {
  constructor(
    public dialogRef: MatDialogRef<DocumentRejectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onReject(): void {
    console.log(this.data)
    if (!this.data.reasonCode) {
      //alert('Please select a reason code.');
      this.showInformation('Input Error', 'Please select a reason code.')

    } else {
      this.dialogRef.close(this.data);
    }
  }

  showInformation(result: any, message: string): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      width: '300px',
      height: '250px',
      data: { resultStatus: result, showMessage: message },
    });
  }
}

