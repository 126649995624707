<div class="detailed-View">

        <div class="title-header">
          <div class="detail-header">
            <h2>Shipment - {{shipmentData.grReference}}</h2>
          </div>
        </div>
      
        
        <div class="divider-line"></div>
      
        <div class="info-grid">
                <div>
                        <div class="info-item">
                                <span class="info-label">Date created</span>
                                <span>[Data]</span>
                              </div>
                          
                              <div class="info-item">
                                <span class="info-label">Created by</span>
                                <span>[Data]</span>
                              </div>
                </div>

                <div>
                        <div class="info-item">
                                <span class="info-label">Vessel Name & Voyage no.</span>
                                <span>{{shipmentData.vesselVoyage}}</span>
                              </div>
                          
                              <div class="info-item">
                                <span class="info-label">Container no.</span>
                                <span>{{shipmentData.containerNumber}}</span>
                              </div>
                </div>

                <div>
                        <div class="info-item">
                                <span class="info-label">Booking ref.</span>
                                <span>{{shipmentData.bookingRef}}</span>
                              </div>
                          
                              <div class="info-item">
                                <span class="info-label">GR ref.</span>
                                <span>{{shipmentData.grReference}}</span>
                              </div>
                </div>
      
        </div>
      
        <mat-button-toggle-group
          name="favoriteColor"
          aria-label="Favorite Color"
          [hideSingleSelectionIndicator]="true"
          class="tab-menu"
          [(ngModel)]="selectedTab">
          
          <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.trackingTab">Tracking</mat-button-toggle>
          <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.documentTab">Documents</mat-button-toggle>
          <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.alertTab">Alerts</mat-button-toggle>
          <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.detailsTab">Details</mat-button-toggle>
        
        </mat-button-toggle-group>
        <div class="tab-content">
                <ng-container  *ngIf="selectedTab === detailTabbedViewEnum.documentTab">
                        <app-document-management></app-document-management>
                </ng-container>
                <ng-container  *ngIf="selectedTab === detailTabbedViewEnum.alertTab">
                        <app-shipment-alert-tab [shipmentData]="shipmentData"[alertData]="alertData"></app-shipment-alert-tab>
                </ng-container>
                <ng-container  *ngIf="selectedTab === detailTabbedViewEnum.detailsTab">
                        <app-alert-shipment-details></app-alert-shipment-details>
                </ng-container>
        </div>

</div>





      

      
          
      