import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { BookingService } from '../service/booking/booking.service';
import { BookingModel } from '../models/booking-data-model';

@Component({
  selector: 'app-booking-detailed-view',
  templateUrl: './booking-detailed-view.component.html',
  styleUrl: './booking-detailed-view.component.css'
})
export class BookingDetailedViewComponent implements OnInit {

  private bookingParameter: string = '';
  public booking = new BookingModel ;
  constructor(private bookingService: BookingService, private communicationService: CommunicationService) { }

  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { this.bookingParameter = param });
    this.getBookingListDetail(this.bookingParameter);
  }

  private getBookingListDetail(grRefNoParam: string): void {
    this.bookingService.getSampleBookingByGrRef(grRefNoParam).subscribe(data => { this.booking = data })
  }
}
