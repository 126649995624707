import { Component, Input, OnInit } from '@angular/core';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { CommunicationService } from '../service/communication/communication.service';
import { AlertService } from '../service/alerts/alert.service';
import { Alert } from '../models/alerts/alerts';
import { ShipmentModel } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';


@Component({
  selector: 'app-detailed-shipment',
  templateUrl: './detailed-shipment.component.html',
  styleUrl: './detailed-shipment.component.css'
})
export class DetailedShipmentComponent implements OnInit {
  detailTabbedViewEnum = detailTabbedViewEnum
  detailTabbedViewEnumSelected = detailTabbedViewEnum.trackingTab
  alertData: Alert[] = [];
  shipmentData: ShipmentModel={
    consigneeCode: '',
    consignee: '',
    vesselVoyage: '',
    destination: '',
    bookingReference: '',
    shipmentStatus: '',
    bookingRef: '',
    transshipmentVessel: '',
    containerNumber: '',
    grReference: 0,
    client: '',
    eta: new Date(),
    etd: new Date(),
    daysPriorToETA: 0,
    loadPort: '',
    dischargePort: '',
    shipmentStatusColor: '',
    indicators: ''
  };
  documentParameter: string = ''
  @Input() selectedTab = this.detailTabbedViewEnumSelected

  constructor( private communicationService: CommunicationService,private alertDataService: AlertService,private shipmentDataService: ShipmentService){}
  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { this.documentParameter = param });
    this.getAlertsForGRRef(this.documentParameter);
    this.getShipemntsForGRRef(this.documentParameter);
  }
  private getAlertsForGRRef(alertParameter: string): void {
    this.alertDataService.getAlertsForGRRef(alertParameter).subscribe(data => { this.alertData = data })
    console.log(this.alertData);
  }
  private getShipemntsForGRRef(shipmentParameter: string): void {
    this.shipmentDataService.getShipmentsForGRRef(shipmentParameter).subscribe(
      (data) => {
        this.shipmentData = data[0]; 
        console.log('Just the data : '+data[0].consigneeCode);
        console.log("shimpentData :"+this.shipmentData.bookingRef);
        // console.log(this.shipmentData);  // Correctly assign the data to shipmentData
      })};

}
