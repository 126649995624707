import { Component, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ShipmentModel } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { ColumnSetup } from '../models/column-setup';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { ApiUserService } from '../service/user/api-user.service';
import { AlertService } from '../service/alerts/alert.service';
import { DocumentManagementComponent } from '../document-management/document-management.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { InformationComponent } from '../dialog/information/information.component';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { number } from 'echarts';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.css'],
})
export class ShipmentListComponent implements OnInit {
  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<ShipmentModel>;
  private apiUserService = inject(ApiUserService)
  // private document = inject(DocumentManagementComponent);
  private destroy$ = new Subject<void>();
  shipmentData: ShipmentModel[] = [];
  filter!: FilterData;
  detailView = detailView;
  detailTabbedViewEnum = detailTabbedViewEnum;
  displayedColumns: ColumnSetup[] = [];
  isCustUser: boolean = false;
  extractedColumns: string[] = [];
  filterId: number = 0;
  startDate?: Date = new Date;
  endDate?: Date = new Date;
  templates: AlertSubscriptionModel[] = [];
    _snackBar: any;
    horizontalPosition: any;
    verticalPosition: any;


  configureColumns(): void {
    this.displayedColumns = [
      { name: 'shipmentStatusColor', header: '', type: 'string', visible: true },
      { name: 'customerCode', header: 'Customer', type: 'string', visible: !this.isCustUser },
      { name: 'grReference', header: 'GR Ref No', type: 'string', visible: true },
      { name: 'indicators', header: 'Indicators', type: 'string', visible: true },
      { name: 'containerNumber', header: 'Container No', type: 'string', visible: true },
      { name: 'consignee', header: 'Consignee', type: 'string', visible: true },
      { name: 'etd', header: 'ETD', type: 'string', visible: true },
      { name: 'eta', header: 'ETA', type: 'string', visible: true },
      { name: 'actions', header: 'Actions', type: 'string', visible: true }
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  };

  shipmentDataSource = new MatTableDataSource<ShipmentModel>();

  constructor(private alertDataService: AlertService, private shipmentDataService: ShipmentService, private dialog: MatDialog, private communicationService: CommunicationService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private appComponent: AppComponent) {

    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    )
  }

  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isCustUser = this.apiUserService.IsCustUser;
          }
      });

    this.isCustUser = this.apiUserService.IsCustUser;


    this.shipmentDataService.statusFilterToggle$.subscribe((value) => {
      if (value) {
        this.applyFilter(value);
      } else {
        this.applyFilter('');
      }
    });

    this.shipmentDataService.sendFilterTransfer$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (t) => {
          this.filter = t.filter;
          this.filterId = t.filterId;
          this.startDate = t.startDate;
          this.endDate = t.endDate;
          this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
        },
        error: (error) => {
          console.error('Send FilterModel Error', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      });
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Completed':
        return '#36DE6F';
      default:
        return '#368EDE';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.shipmentDataService.getShipments(filterId, filterData, startDate, endDate).subscribe(data => {
      this.configureColumns();
      this.shipmentDataSource.sort = this.sort;
      this.shipmentDataSource.paginator = this.paginator;
      this.shipmentDataSource.data = data;
      console.log(data);
    });
  }

  viewDetails(row: any, view: detailView, tab: detailTabbedViewEnum): void {
    console.log('Viewing details for:', row);
    this.communicationService.toggleDetailView(view, tab);
    this.communicationService.getDetailViewParameter(row['grReference']);
    this.openDetailDrawer.emit();
  }

  subscribeToAlert(row: any): void {
    const notificationModel = new AlertNotificationModel();
    notificationModel.AlertCode = '';
    notificationModel.CustomerCode = row.customerCode;
    notificationModel.GRReference = row.grReference;
    notificationModel.ContainerNo = row.containerNumber;
    notificationModel.TemplateGroup = 'Shipment';
 
    this.showLoading(true);

    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
      .pipe(
        catchError(error => {
          console.error('Error fetching subscription templates:', error);
          return of([]);
        })
      )
      .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {
     
        this.showLoading(false);

        const subscriptionInput = new AlertSubscriptionInputModel();
        subscriptionInput.notificationModel = notificationModel;
        subscriptionInput.templates = subscriptionTemplates;

        const dialogRef = this.dialog.open(SubscriptionComponent, {
          data: { subscriptionList: subscriptionTemplates } 
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            subscriptionInput.templates = result;

            //this.showLoading(true);

            this.alertDataService.updateAlertSubscription(subscriptionInput)
              .pipe(
                catchError(error => {
                  console.error('Error updating subscriptions:', error);
                  return of(null);
                })
              )
              .subscribe(response => {

                //this.showLoading(false);

                if (response) {
                  console.log('Subscription updated:', response);
                  this.openSnackBar('Your subscriptions has been updated"');
                }
              });
          }
        });
      });
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: ColumnSetup[]) {
    return columns.map(col => col.name);
  }

  applyFilter(filterValue: string) {
    if (!filterValue) {
      this.shipmentDataSource.filter = '';
    } else {
      this.shipmentDataSource.filter = filterValue.trim().toLowerCase();
    }
  }
}
